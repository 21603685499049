import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Echo from 'laravel-echo';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import ArrowDown from '../../../assets/images/ArrowDown.png';
import ArrowUp from '../../../assets/images/ArrowUp.png';
import Dots from '../../../assets/images/dots.png';
import Edit from '../../../assets/images/edit.png';
import Eye from '../../../assets/images/eye.png';
import HideEye from '../../../assets/images/hide-eye.png';
import ApiServices from '../../../helpers/ApiServices';
import HelperModule from '../../../helpers/HelperModule';
import {
  setCatalogList,
  setItemsSubList,
} from '../../../store/reducers/CatalogDataReducer';
import { setCrossSellingList } from '../../../store/reducers/CrossSellingReducer';
import { setHotSellingDrp } from '../../../store/reducers/HotSellingDrpReducer';
import { setHotSellingList } from '../../../store/reducers/HotSellingReducer';
import { setItemsList } from '../../../store/reducers/ItemsListReducer';
import { setModifierList } from '../../../store/reducers/ModifierDataReducer';
import { setUpSellingList } from '../../../store/reducers/UpSellingReducer';

import '../MenuDetails.css';
import ItemVariationComponent from '../ItemVariation/ItemVariationComponent';
import Tag from '../Tag/Tag';
import AllergenComponent from '../AllergenComponent/AllergenComponent';
import UpsellingItemsComponent from '../UpsellingItemsComponent/UpsellingItemsComponent';
import CrossSellingComponent from '../CrossSellingComponent/CrossSellingComponent';
import ModifierComponent from '../ModifierComponent/ModifierComponent';
import TaxComponent from '../TaxComponent/TaxComponent';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

let UserCodeData: any;
let BasicListData: any;
let AuthTokenId: any;
let echoInstance: any = null;

type ItemComponentProps = {
  item: any;
  HotSellingChangeStatus: any;
  setOrderOptions: any;
  setSpecialRequests: any;
  setCategoryLoading: any;
  setLoadingStatusChangeCategory: any;
  setLoadingStatusChangeItems: any;
  setLoadingStatusChange: any;
  setTagEditModal: any;
  globalCategoryPosId: any;
  setSchedule: any;
  setCrossSellingData: any;
  setUpSellingData: any;
  setEditItemObject: any;
  setKimStock: any;
  setIsLoading: any;
  setEditItem: any;
  setEditCrossSelling: any;
  setEditUpSelling: any;
  setItemDesc: any;
  setListHide: any;
  changeStatus: any;
  itemIndex: any;
};

const ItemComponent = ({
  item,
  HotSellingChangeStatus,
  setOrderOptions,
  setSpecialRequests,
  setCategoryLoading,
  setLoadingStatusChangeCategory,
  setLoadingStatusChangeItems,
  setLoadingStatusChange,
  setTagEditModal,
  globalCategoryPosId,
  setSchedule,
  setCrossSellingData,
  setUpSellingData,
  setEditItemObject,
  setKimStock,
  setIsLoading,
  setEditItem,
  setEditCrossSelling,
  setEditUpSelling,
  setItemDesc,
  setListHide,
  changeStatus,
  itemIndex,
}: ItemComponentProps) => {
  const connectEcho = () => {
    if (!echoInstance) {
      try {
        echoInstance = new Echo({
          broadcaster: 'pusher',
          key: 'f949221d29fc0b04400a',
          cluster: 'ap2',
          wsPort: 443,
          disableStats: false,
          encrypted: true,
        });
        console.log('Echo connected successfully.');
      } catch (error) {
        console.error('Error connecting Echo:', error);
      }
    } else {
      console.log('Echo is already connected.');
    }
  };

  const ListItems = [
    {
      variation: 'Item Variation',
      // "allergen": "Allergen",
      modifier_set: 'Modifier Set',
      tax: 'Tax',
    },
  ];

  const { items_sub_list } = useSelector((state: any) => state.catalogDetails);
  const { UpSellingList } = useSelector(
    (state: any) => state.upSellingListDetails
  );
  const { CrossSellingList } = useSelector(
    (state: any) => state.crossSellingListDetails
  );
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [variationHide, setVariationHide] = React.useState(false);
  const [tagHide, setTagHide] = React.useState(false);
  const [itemsListArray, setItemsListArray]: any = useState([]);
  const [itemVariationsList, setItemVarationsList]: any = useState([]);
  const [selectedItemVariationId, setSelectedItemVariationId]: any =
    useState('');
  const { menu_code }: any = useParams();
  const [taxHide, setTaxHide] = useState(false);
  const [modifierHide, setModifierHide] = useState(false);
  const [modifierIdData, setModifierIdData] = useState('');
  const [itemState, setItemState]: any = useState({});
  const [crosssellingHide, setCrosssellingHide] = useState(false);
  const [taxData, setTaxData]: any = useState('');
  const [isItemsOpens, setIsItemsOpens]: any = useState([]);
  const [editAlergencySelect, setEditAlergencySelect]: any = useState({});
  const [tagView, setTagView]: any = useState(false);
  const [allergenView, setAllergenView]: any = useState(false);
  const [globalItemId, setGlobalItemId]: any = useState(null);
  const [globalItemNameId, setGlobalItemNameId]: any = useState(null);
  const [upSellingStatus, setUpSellingStatus]: any = useState();
  const [crossSellingStatus, setCrossSellingStatus]: any = useState();
  const [allergenStatus, setAllergenStatus]: any = useState();
  const [itemallergenStatus, setItemAllergenStatus]: any = useState();
  const [modifierStatus, setModifierStatus]: any = useState();
  const [variation_status, setVariationStatus]: any = useState();
  const [globalVariationId, setGlobalVariationId] = useState(null);
  const [loadingStatusItemsCategory, setLoadingStatusItemsCategory]: any =
    useState({});
  const [loadingvariationStatus, setLoadingvariationStatus]: any = useState({});
  const [loadingallergenStatus, setLoadingallergenStatus]: any = useState({});
  const [loadingitemalleregenStatus, setLoadingitemallergenStatus]: any =
    useState({});
  const [loadingUpsellingStatus, setLoadingUpsellingStatus]: any = useState({});
  const [loadingCrossSellingStatus, setLoadingCrossSellingStatus]: any =
    useState({});
  const [loadingItemvariationStatus, setLoadingItemvariationStatus]: any =
    useState({});
  const [loadingmodifierStatus, setLoadingmodifierStatus]: any = useState({});
  const [upsellingLoading, setUpsellingLoading] = useState(true);
  const [crossSllingLoading, setCrossSllingLoading] = useState(false);

  ///////////////////////////USE_EFFECT///////////////////////////

  useEffect(() => {
    const UserCode = localStorage.getItem('UserCode');
    UserCodeData = UserCode;
  }, []);

  useEffect(() => {
    MenusDataItems();
    BasicCatalogData();
  }, [menu_code, UserCodeData]);

  useEffect(() => {}, [itemsListArray]);

  useEffect(() => {
    if (UpSellingList && UpSellingList.length > 0) {
      setUpsellingLoading(false);
    }
  }, [UpSellingList]);

  useEffect(() => {
    if (CrossSellingList && CrossSellingList.length > 0) {
      setCrossSllingLoading(false);
    }
  }, [CrossSellingList]);

  useEffect(() => {
    GetHotSellingData();
    GetTaxData();
    GetHotSellingDropDown();
  }, []);

  //   useEffect(() => {
  //     connectEcho();
  //     subscribeChannel();
  //     checkConnectionState();
  //     return () => {
  //       disconnectEcho();
  //       checkConnectionState();
  //     };
  //   }, [globalCategoryPosId]);

  ///////////////////////////API CALLS///////////////////////////

  const ItemDescClose = (itemId: any) => {
    setListHide({ [itemId]: false });
    setItemDesc(false);
  };

  const HandleVariation = () => {
    setVariationHide(false);
  };

  const HandleTag = () => {
    setTagView(false);
  };

  const HandleAllergen = () => {
    setAllergenView(false);
  };

  const UpsellingClose = () => {
    setTagHide(false);
  };

  const CrosssellingClose = () => {
    setCrosssellingHide(false);
  };

  const UpSellingModal = () => {
    setEditUpSelling(true);
  };

  const CrossSellingModal = () => {
    setEditCrossSelling(true);
  };

  const EditItemModal = (item: any) => {
    setEditItem(true);
    setEditItemObject(item);
    setKimStock(item?.stock);
    setSpecialRequests({ ...item?.special_requests });
    // setOrderOptions({
    // 	dine_in: !!item?.order_type?.dine_in,
    // 	take_out: !!item?.order_type?.take_out,
    // 	both: !!item?.order_type?.both,
    // });
    setOrderOptions({
      dine_in: !!item?.order_type?.dine_in,
      take_out: !!item?.order_type?.take_out,
    });

    let schedules = item?.items_availability?.map((elem: any) => {
      return {
        [elem.day_of_week]: {
          start_time: elem.start_time,
          end_time: elem.end_time,
        },
      };
    });

    let obj: any = {};
    schedules?.map((elem: any) => {
      let keys = Object.keys(elem);
      let day = keys[0];
      obj[day] = elem[day];
    });

    setSchedule({ ...obj });

    item?.all_allergens?.map((elem: any) => {
      if (elem?.status) {
        setEditAlergencySelect({ ...editAlergencySelect, [item.id]: elem?.id });
      }
    });
  };

  const BasicCatalogData = async () => {
    const headers = {
      Authorization: `Bearer ${AuthTokenId}`,
    };
    try {
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
          ApiServices.catalog_list +
          UserCodeData +
          '/' +
          menu_code,
        headers
      );
      if (response.status === true) {
        BasicListData = response.taxes.data;
        const ModifierListID = response.modifiers_list?.map(
          (modifierID: any) => modifierID.modifier_list_id
        );
        for (const modifier_ID of ModifierListID) {
          await ModifierDataItems(setModifierIdData);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const MenusDataItems = async () => {
    const headers = {
      Authorization: `Bearer ${AuthTokenId}`,
    };
    try {
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
          ApiServices.category_list +
          `?menu_code=${menu_code}&user_code=${UserCodeData}&device_type=vendor_web`,
        headers
      );
      if (response.status === true) {
        dispatch(setCatalogList(response));
        const posIds = response.categoriesList?.map(
          (category: any) => category.pos_id
        );
      } else {
        console.log('Error');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const ModifierDataItems = async (modifierIdData: any) => {
    const headers = {
      Authorization: `Bearer ${AuthTokenId}`,
    };
    try {
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
          ApiServices.modifiers_list +
          `?menu_code=${menu_code}&user_code=${UserCodeData}&modifier_list_id=${modifierIdData}`,
        headers
      );
      if (response.status === true) {
        dispatch(setModifierList(response.modifiers));
      } else {
        console.log('Error');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const ItemsListData = async (categoryPosId: any) => {
    setCategoryLoading(true);
    const headers = {
      Authorization: `Bearer ${AuthTokenId}`,
    };
    try {
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
          ApiServices.items_list +
          `?menu_code=${menu_code}&user_code=${UserCodeData}&category_id=${categoryPosId}&device_type=vendor_web`,
        headers
      );
      if (response.status === true) {
        setItemsListArray(response.items);
        setItemsList(response.items);
        setModifierIdData(response.items[0].modifier_list_id);
        const ItemVariationId = response?.items?.map(
          (itemVariationList: any) => itemVariationList.item_id
        );
        setSelectedItemVariationId(ItemVariationId[0]);
        setCategoryLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const ItemsListSubData = async (itemId: any) => {
    const headers = {
      Authorization: `Bearer ${AuthTokenId}`,
    };
    try {
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
          ApiServices.items_list +
          '/' +
          `${itemId}?menu_code=${menu_code}&user_code=${UserCodeData}&device_type=vendor_web`,
        headers
      );
      if (response.status === true) {
        dispatch(setItemsSubList(response.item));
        setUpSellingData(response.item.up_selling_items);
        // dispatch(setCrossSellingList(response.item.cross_selling_items));
        // dispatch(setUpSellingList(response.item.up_selling_items));
        setCrossSellingData(response.item.cross_selling_items);
        console.log('::::::::::+++++++');
        setAllergenStatus(response.item.allergen_status);
        setItemAllergenStatus(response.item.item_allergens[0].pivot.status);
        console.log(
          response.item.item_allergens[0].pivot.status,
          'allergenStatus{}{}{}{}'
        );
        setUpSellingStatus(response.item.upselling_status);
        setCrossSellingStatus(response.item.crossselling_status);
        setModifierStatus(response.item.modifier_status);
        setVariationStatus(response.item.variation_status);
      } else {
        console.log('Error');
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const ItemVariationsList = async (item_id: any) => {
    const headers = {
      Authorization: `Bearer ${AuthTokenId}`,
    };
    try {
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
          ApiServices.item_variations +
          `?item_id=${globalItemNameId}&user_code=${UserCodeData}&menu_code=${menu_code}&device_type=vendor_web`,
        headers
      );
      if (response.status === true) {
        setItemVarationsList(response.items);
        return response?.items?.map((itemsList: any) => itemsList.category_id);
      } else {
        console.log('Error');
        return [];
      }
    } catch (error) {
      console.log(error);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const VariationClose = async () => {
    try {
      setVariationHide(true);
      await ItemVariationsList(selectedItemVariationId);
    } catch (error) {
      console.error('API Call Error:', error);
    }
  };

  const TagClose = async () => {
    setTagView(true);
  };

  const AllergenClose = async () => {
    setAllergenView(true);
  };

  const isItemOpen = (itemId: any) => !!itemState[itemId];

  const handleItemSelection = (itemId: any) => {
    setListHide({ [itemId]: true });
    setItemDesc(true);
    setItemState((prevState: any) => ({
      ...prevState,
      [itemId]: true,
    }));
    ItemsListSubData(itemId);
  };

  const handleItemClose = (itemId: any) => {
    setIsItemsOpens(isItemsOpens.filter((elem: any) => elem != itemId));
    setListHide({ [itemId]: false });
    setItemDesc(false);
    setItemState((prevState: any) => ({
      ...prevState,
      [itemId]: !prevState[itemId],
    }));
  };

  const TaxHandler = () => {
    setTaxHide(true);
  };

  const TaxHandlerClose = () => {
    setTaxHide(false);
  };

  const ModifierHandler = () => {
    setModifierHide(true);
    ModifierDataItems(modifierIdData);
  };

  const HandleModifierClose = () => {
    setModifierHide(false);
    setModifierIdData('');
  };

  const GetHotSellingData = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${AuthTokenId}`,
      };
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
          ApiServices.get_hot_selling_data +
          UserCodeData +
          '/' +
          menu_code,
        headers
      );
      if (response.status === true) {
        dispatch(setHotSellingList(response.items));
      } else {
        console.log('Error Response', response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const UpSellingDataList = async () => {
    setUpsellingLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${AuthTokenId}`,
      };
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
          ApiServices.get_up_selling_data +
          `?item_ids[]=${globalItemId}`,
        headers
      );
      if (response.status === true) {
        dispatch(setUpSellingList(response.data));
        setUpsellingLoading(false);
      } else {
        console.log('Error Response', response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setUpsellingLoading(false);
    }
  };

  const UpSellingOpen = () => {
    setTagHide(true);
    UpSellingDataList();
  };

  const CrossSellingDataList = async () => {
    setCrossSllingLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${AuthTokenId}`,
      };
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
          ApiServices.get_cross_selling_data +
          `?item_ids[]=${globalItemId}`,
        headers
      );
      if (response.status === true) {
        dispatch(setCrossSellingList(response?.data?.cross_selling_items));
        setCrossSllingLoading(false);
      } else {
        console.log('Error Response', response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCrossSllingLoading(false);
    }
  };

  const CrossSellingOpen = () => {
    setCrosssellingHide(true);
    CrossSellingDataList();
  };

  const GetTaxData = async () => {
    const headers = {
      Authorization: `Bearer ${AuthTokenId}`,
    };
    try {
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
          ApiServices.tax +
          `?menu_code=${menu_code}&user_code=${UserCodeData}`,
        headers
      );
      if (response.status === true) {
        setTaxData(response.data[0]);
      } else {
        console.log('Error Response', response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetHotSellingDropDown = async () => {
    const headers = {
      Authorization: `Bearer ${AuthTokenId}`,
    };
    try {
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
          ApiServices.hot_selling_edit_drop_down +
          '/' +
          menu_code,
        headers
      );
      if (response.status === true) {
        dispatch(setHotSellingDrp(response.items));
      } else {
        console.log('Error Response', response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //   const HotSellingChangeStatus = async () => {
  //     try {
  //       const response = await HelperModule().getMethod(
  //         ApiServices.base_URL + ApiServices.hot_selling_status + `${menu_code}`
  //       );
  //       if (response.status === true) {
  //         setEditHotsSellingStatus(response.data.hot_selling_status);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   useEffect(() => {
  //     HotSellingChangeStatus();
  //   }, []);

  const subscribeChannel = () => {
    try {
      echoInstance.channel('miniosk').listen('.soldout', (e: any) => {
        if (e.type === 'CATEGORY') {
          MenusDataItems();
          console.log('ITEM UPDATE');
        } else if (e.type === 'ITEM') {
          MenusDataItems();
          ItemsListData(globalCategoryPosId);
          console.log(e.type, 'type>>>>>>');
          console.log(globalCategoryPosId, '::globalCategoryPosId::');
        }
      });
      console.log('Subscribed to channel successfully.');
    } catch (error) {
      console.error('Error subscribing to channel:', error);
    }
  };

  const disconnectEcho = () => {
    try {
      if (echoInstance) {
        echoInstance.disconnect();
        echoInstance = null;
        console.log('Echo disconnected successfully.');
      } else {
        console.log('Echo is not connected.');
      }
    } catch (error) {
      console.error('Error disconnecting Echo:', error);
    }
  };

  //   const checkConnectionState = () => {
  //     try {
  //       if (
  //         echoInstance &&
  //         echoInstance.connector &&
  //         echoInstance.connector.socket
  //       ) {
  //         const isConnected = echoInstance.connector.socket.connected;
  //         console.log(
  //           'Connection state:',
  //           isConnected ? 'Connected' : 'Disconnected'
  //         );
  //       } else {
  //         console.log('Echo connection not initialized or socket not available.');
  //       }
  //     } catch (error) {
  //       console.error('Error checking connection state:', error);
  //     }
  //   };

  const reorder = (
    list: any,
    startIndex: any,
    endIndex: any,
    draggableId: any
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    const items = result.map((item: any, index: any) => {
      return {
        ...item,
        position: index + 1,
      };
    });
    return items;
  };

  //   console.log('item', item);

  return (
    // <DragDropContext onDragEnd={onDragEnd}>
    <Droppable droppableId={item.item_id} type="product">
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          <Draggable key={item.id} draggableId={item.item_id} index={itemIndex}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <div
                  className="sub-row"
                  style={{
                    width: '50.36vw',
                  }}
                  key={item.id}
                >
                  <div
                    className={'deails-row-sub'}
                    style={{
                      backgroundColor: '#FFFFFF',
                      border: '1px solid #201C1C52',
                      borderRadius: '16px',
                      marginBottom: '16px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div className="hot-selling">
                        <>
                          <img
                            alt="dots"
                            src={Dots}
                            style={{
                              height: '24px',
                              width: '24px',
                              marginLeft: '16px',
                            }}
                          />
                          <div className="category-container">
                            <p
                              className="category-desc"
                              style={{ color: '#8B5CFF' }}
                            >
                              {' '}
                              {item.item}{' '}
                            </p>
                            <h3 className="hot-selling-header">
                              {' '}
                              {item.name}{' '}
                            </h3>
                          </div>
                          {item.sold_out === 1 ? (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: '10px',
                                backgroundColor: '#F34A4A29',
                                borderRadius: '8px',
                              }}
                            >
                              <h1
                                style={{
                                  fontSize: '10px',
                                  margin: 0,
                                  padding: '5px',
                                  fontFamily: 'Poppins',
                                  fontWeight: 'bold',
                                  color: '#F34A4A',
                                }}
                              >
                                {' '}
                                Sold Out
                              </h1>
                            </div>
                          ) : null}
                        </>
                      </div>
                      <div className="operate-container">
                        {!isItemsOpens.find((elem: any) => elem == item.id) ? (
                          <Button
                            onClick={() => {
                              setIsItemsOpens([item.id]);
                              isItemOpen(item.id);
                              handleItemSelection(item.id);
                              setGlobalItemId(item.id);
                              setGlobalItemNameId(item.item_id);
                            }}
                            className="show-btn"
                            style={{
                              backgroundColor: '#FFFFFF',
                              color: '#201C1CB8',
                              fontSize: '12px',
                              fontWeight: '400',
                              fontFamily: 'Poppins',
                              cursor: 'pointer',
                            }}
                          >
                            <img
                              src={ArrowDown}
                              alt="down"
                              style={{
                                height: '20px',
                                width: '20px',
                                marginRight: '10px',
                              }}
                            />
                            Show
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              handleItemClose(item.id);
                              ItemDescClose(item);
                            }}
                            className="show-btn"
                            style={{
                              backgroundColor: '#FFFFFF',
                              color: '#201C1CB8',
                              fontSize: '12px',
                              fontWeight: '400',
                              fontFamily: 'Poppins',
                              cursor: 'pointer',
                            }}
                          >
                            <img
                              src={ArrowUp}
                              alt="up"
                              style={{
                                height: '20px',
                                width: '20px',
                                marginRight: '10px',
                              }}
                            />
                            Hide
                          </Button>
                        )}
                        <button
                          onClick={() => EditItemModal(item)}
                          className="show-btn"
                          style={{
                            backgroundColor: '#FFFFFF',
                            cursor: 'pointer',
                          }}
                        >
                          <img
                            src={Edit}
                            alt="edit"
                            style={{
                              height: '20px',
                              width: '20px',
                              marginRight: '10px',
                            }}
                          />
                          Edit
                        </button>

                        {loadingStatusItemsCategory[item.id] ? (
                          <CircularProgress
                            size={20}
                            style={{
                              height: '20px',
                              width: '20px',
                              marginRight: '10px',
                              marginLeft: '16px',
                            }}
                            sx={{ color: '#8B5CFF' }}
                          />
                        ) : (
                          <>
                            {item.status === 0 ? (
                              <div style={{ cursor: 'pointer' }}>
                                <img
                                  src={HideEye}
                                  alt="edit"
                                  style={{
                                    height: '20px',
                                    width: '20px',
                                    marginRight: '10px',
                                    marginLeft: '16px',
                                  }}
                                  onClick={() => {
                                    changeStatus('items', item.id, null);
                                  }}
                                />
                              </div>
                            ) : (
                              <div style={{ cursor: 'pointer' }}>
                                <img
                                  src={Eye}
                                  alt="edit"
                                  style={{
                                    height: '20px',
                                    width: '20px',
                                    marginRight: '10px',
                                    marginLeft: '16px',
                                  }}
                                  onClick={() => {
                                    changeStatus('items', item.id, null);
                                  }}
                                />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {isItemOpen(item.id) && (
                      <>
                        {ListItems?.length > 0 &&
                          ListItems?.map((item: any) => (
                            <>
                              <ItemVariationComponent
                                variationHide={variationHide}
                                HandleVariation={HandleVariation}
                                VariationClose={VariationClose}
                                setGlobalVariationId={setGlobalVariationId}
                                item={item}
                                loadingvariationStatus={loadingvariationStatus}
                                globalItemId={globalItemId}
                                variation_status={variation_status}
                                changeStatus={changeStatus}
                                itemVariationsList={itemVariationsList}
                                loadingItemvariationStatus={
                                  loadingItemvariationStatus
                                }
                              />
                              <Tag
                                tagView={tagView}
                                TagClose={TagClose}
                                items_sub_list={items_sub_list}
                                setTagEditModal={setTagEditModal}
                                HandleTag={HandleTag}
                              />
                              <AllergenComponent
                                allergenView={allergenView}
                                HandleAllergen={HandleAllergen}
                                loadingallergenStatus={loadingallergenStatus}
                                globalItemId={globalItemId}
                                allergenStatus={allergenStatus}
                                changeStatus={changeStatus}
                                items_sub_list={items_sub_list}
                                loadingitemalleregenStatus={
                                  loadingitemalleregenStatus
                                }
                                itemallergenStatus={itemallergenStatus}
                                AllergenClose={AllergenClose}
                              />
                              <UpsellingItemsComponent
                                tagHide={tagHide}
                                UpSellingOpen={UpSellingOpen}
                                UpsellingClose={UpsellingClose}
                                UpSellingModal={UpSellingModal}
                                loadingUpsellingStatus={loadingUpsellingStatus}
                                globalItemId={globalItemId}
                                upSellingStatus={upSellingStatus}
                                changeStatus={changeStatus}
                                upsellingLoading={upsellingLoading}
                                UpSellingList={UpSellingList}
                              />
                              <CrossSellingComponent
                                item={item}
                                crosssellingHide={crosssellingHide}
                                CrossSellingOpen={CrossSellingOpen}
                                CrosssellingClose={CrosssellingClose}
                                CrossSellingModal={CrossSellingModal}
                                loadingCrossSellingStatus={
                                  loadingCrossSellingStatus
                                }
                                globalItemId={globalItemId}
                                crossSellingStatus={crossSellingStatus}
                                changeStatus={changeStatus}
                                crossSllingLoading={crossSllingLoading}
                                CrossSellingList={CrossSellingList}
                              />
                              <ModifierComponent
                                item={item}
                                modifierHide={modifierHide}
                                ModifierHandler={ModifierHandler}
                                HandleModifierClose={HandleModifierClose}
                                loadingmodifierStatus={loadingmodifierStatus}
                                globalItemId={globalItemId}
                                modifierStatus={modifierStatus}
                                changeStatus={changeStatus}
                                items_sub_list={items_sub_list}
                              />
                              <TaxComponent
                                taxHide={taxHide}
                                TaxHandler={TaxHandler}
                                TaxHandlerClose={TaxHandlerClose}
                                taxData={taxData}
                              />
                            </>
                          ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Draggable>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
    // </DragDropContext>
  );
};

export default ItemComponent;
