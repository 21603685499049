import { Box, Checkbox, FormControlLabel, Modal } from "@mui/material";
import Refresh from "../../../assets/images/refresh.png";
import Cross from "../../../assets/images/Cross.png";
import React from "react";

type EditVariationModalProps = {
    editVariation: boolean;
    setEditVariation: (value: boolean) => void;
    editAllergen: boolean;
};

const EditVariationModal = ({
    editVariation,
    setEditVariation,
    editAllergen
}:EditVariationModalProps) => {
  return (
    <Modal
      open={editVariation}
      onClose={() => setEditVariation(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          bottom: editAllergen ? "0" : "-100%",
          transition: "bottom 0.3s ease-in-out",
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "47.73vw",
          height: "86.74vh",
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          padding: "16px",
          borderWidth: 0,
          overflowY: "auto",
        }}
      >
        <style>{`:: -webkit - scrollbar { width: 6px; } :: -webkit - scrollbar - track { display: none; } :: -webkit - scrollbar - thumb { background - color: transparent; } `}</style>
        <div className="import-title-container">
          <h3 className="import-title">Edit Item Variation</h3>
          <div className="category-items-btn" style={{ display: "flex" }}>
            <button className="reimport-btn">
              <img
                src={Refresh}
                alt="refresh"
                style={{ height: "16px", width: "16px", marginRight: "10px" }}
              />
              Reimport
            </button>
            <button className="delete-btn-item">Delete</button>
            <img
              onClick={() => setEditVariation(false)}
              src={Cross}
              alt="Cross"
              style={{
                width: "30px",
                height: "32px",
                backgroundColor: "#F34A4A29",
                borderRadius: "33px",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
        <div className="menu-details">
          <h3 className="menu-title-header">General Details</h3>
          <h3 className="menu-heading">Item Variation Name</h3>
          <input
            placeholder="Menu Name"
            value={"Burger Adda"}
            style={{
              marginTop: "8px",
              width: "44.94vw",
              height: "7.20vh",
              borderRadius: "16px",
              borderWidth: "1px",
              borderColor: "#201C1C3D",
              padding: "10px 16px 10px 16px",
              outline: "none",
              fontWeight: "400",
              fontSize: "14px",
              fontFamily: "Poppins",
            }}
          />
          <p className="input-sub-text">
            This text is the name of the category that will show up in your
            miniosks.
          </p>
          <h3 className="menu-heading">SKU</h3>
          <input
            placeholder="Menu Name"
            value={"Burger Adda"}
            style={{
              marginTop: "8px",
              width: "44.94vw",
              height: "7.20vh",
              borderRadius: "16px",
              borderWidth: "1px",
              borderColor: "#201C1C3D",
              padding: "10px 16px 10px 16px",
              outline: "none",
              fontWeight: "400",
              fontSize: "14px",
              fontFamily: "Poppins",
            }}
          />
          <p className="input-sub-text">
            This is the SKU assigned to your item from Square. You can only edit
            this value from your Square dashboard. If you would like to change
            this value, make the change from your Square dashboard and then
            re-import to Miniosk.
          </p>
          <FormControlLabel
            control={<Checkbox />}
            label="Show this category in miniosks"
            color="#201C1C"
            className="check-box"
            style={{
              fontWeight: "500",
              fontSize: "12px",
              fontFamily: "Poppins",
              height: "20px",
              marginTop: "16px",
            }}
          />
          <p className="input-sub-text">
            Select this checkbox to show this category in your miniosks. If
            disabled, the category and all of its children will be hidden.
          </p>
          <FormControlLabel
            control={<Checkbox />}
            label="Track Square Inventory"
            color="#201C1C"
            className="check-box"
            style={{
              fontWeight: "500",
              fontSize: "12px",
              fontFamily: "Poppins",
              height: "20px",
              marginTop: "16px",
            }}
          />
          <p className="input-sub-text">
            Enable this option to have your miniosksync with Square Inventory
            counts. When items run out of stock, they'll be labeled as Sold Out
            and cannot be ordered. When items are back in stock, customers can
            immediately start ordering those items again.
          </p>
          <h3 className="menu-title-header" style={{ paddingTop: "16px" }}>
            Current Availability at Restaurant KIM
          </h3>
          <FormControlLabel
            control={<Checkbox />}
            label="Show as sold out at Restaurant KIM"
            color="#201C1C"
            className="check-box"
            style={{
              fontWeight: "500",
              fontSize: "12px",
              fontFamily: "Poppins",
              height: "20px",
              marginTop: "16px",
            }}
          />
          <p className="input-sub-text">
            {" "}
            Enable this option to have your minioskshow this item as sold outand
            unavailable for purchase. This value will automatically adjust
            according to inventory or item availability set in Square POS,
            Square Dashboard, Square for Restaurants, etc.
          </p>
          <h3 className="menu-title-header">Photo</h3>
          <p className="title-sub-text">
            This uploaded image will show up in the minioskfor this category,
            depending on any Styling options you select. For best performance,we
            recommend a maximum resolution of 1000 x 1000 pixels.
          </p>
        </div>
        <button className="common-button-blue">Upload Photo</button>
        <h3 className="menu-title-header" style={{ marginTop: "16px" }}>
          Pricing Details
        </h3>
        <h3 className="menu-heading">Price</h3>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            paddingTop: "8px",
          }}
        >
          <input
            placeholder="Menu Name"
            value={"00"}
            style={{
              width: "44.94vw",
              height: "7.20vh",
              borderRadius: "8px",
              borderWidth: "1px",
              borderColor: "#201C1C3D",
              padding: "10px 16px 10px 16px",
              outline: "none",
              fontWeight: "400",
              fontSize: "14px",
              fontFamily: "Poppins",
            }}
          />
          <div style={{ position: "absolute", right: "10px" }}>
            <h3
              style={{
                margin: 0,
                fontWeight: "500",
                fontSize: "14px",
                color: "#201C1C",
                fontFamily: "Poppins",
              }}
            >
              $
            </h3>
          </div>
        </div>
        <FormControlLabel
          control={<Checkbox />}
          label="Custom Price"
          color="#201C1C"
          className="check-box"
          style={{
            fontWeight: "500",
            fontSize: "12px",
            fontFamily: "Poppins",
            height: "20px",
            marginTop: "16px",
          }}
        />
        <p className="input-sub-text">
          Most items are set to a fixed price. You can enable this setting to
          allow customers to type in a custom price.
        </p>
        <div style={{ flexDirection: "row" }}>
          <button className="common-button-blue">Apply</button>
          <button
            onClick={() => setEditVariation(false)}
            className="common-button-cancel"
          >
            Cancel
          </button>
        </div>
      </Box>
    </Modal>
  );
};
export default EditVariationModal;