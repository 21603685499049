import CheckIcon from '@mui/icons-material/Check';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  List,
  Typography,
} from '@mui/material';
import { Autocomplete, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Modal from '@mui/material/Modal';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import Echo from 'laravel-echo';
import { useSnackbar } from 'notistack';
import Pusher from 'pusher-js';
import * as PusherTypes from 'pusher-js';
import React, { lazy, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
// import { useLocation } from "react-drag-reorder";
import { useLocation } from 'react-router-dom';

import ArrowDown from '../../assets/images/ArrowDown.png';
import ArrowUp from '../../assets/images/ArrowUp.png';
import Cross from '../../assets/images/Cross.png';
import Dots from '../../assets/images/dots.png';
import Download from '../../assets/images/Download.png';
import Edit from '../../assets/images/edit.png';
import Eye from '../../assets/images/eye.png';
import FoodBowl from '../../assets/images/foodBowl.png';
import HideEye from '../../assets/images/hide-eye.png';
import Refresh from '../../assets/images/refresh.png';
import BackNavigation from '../../components/BackNavigation';
import EditSellingModel from '../../components/EditSellingModel';
import EditSellingSearchModel from '../../components/EditSellingSearchModel';
import SuccessModal from '../../components/SuccessModal';
import ApiServices from '../../helpers/ApiServices';
import HelperModule from '../../helpers/HelperModule';
import {
  setCatalogList,
  setItemsSubList,
} from '../../store/reducers/CatalogDataReducer';
import { setCrossSellingList } from '../../store/reducers/CrossSellingReducer';
import { setHotSellingDrp } from '../../store/reducers/HotSellingDrpReducer';
import { setHotSellingList } from '../../store/reducers/HotSellingReducer';
import { setHotSellingStatus } from '../../store/reducers/HotSellingReducer';
import { setItemsList } from '../../store/reducers/ItemsListReducer';
import { setItemTagEditName } from '../../store/reducers/ItemsListReducer';
import { setModifierList } from '../../store/reducers/ModifierDataReducer';
import { setUpSellingList } from '../../store/reducers/UpSellingReducer';
import Allergen from './Allergen';
import CategoryModal from './CategoryModal';
import ModifierModal from './Modifier';

import './MenuDetails.css';
import ItemVariationComponent from './ItemVariation/ItemVariationComponent';
import Tag from './Tag/Tag';
import AllergenComponent from './AllergenComponent/AllergenComponent';
import UpsellingItemsComponent from './UpsellingItemsComponent/UpsellingItemsComponent';
import CrossSellingComponent from './CrossSellingComponent/CrossSellingComponent';
import ModifierComponent from './ModifierComponent/ModifierComponent';
import TaxComponent from './TaxComponent/TaxComponent';
import EditVariationModal from './EditVariationModal/EditVariationModal';
import ItemComponent from './ItemComponent/ItemComponent';

var presenceChannel: PusherTypes.PresenceChannel;
let UserCodeData: any;
let BasicListData: any;
let AuthTokenId: any;
let echoInstance: any = null;

interface CustomWindow extends Window {
  Echo?: any;
}

declare const window: CustomWindow;
(window as CustomWindow).Echo = null;
(window as any).Pusher = Pusher;

function MenuDetails() {
  const connectEcho = () => {
    if (!echoInstance) {
      try {
        echoInstance = new Echo({
          broadcaster: 'pusher',
          key: 'f949221d29fc0b04400a',
          cluster: 'ap2',
          wsPort: 443,
          disableStats: false,
          encrypted: true,
        });
        console.log('Echo connected successfully.');
      } catch (error) {
        console.error('Error connecting Echo:', error);
      }
    } else {
      console.log('Echo is already connected.');
    }
  };

  const ListItems = [
    {
      variation: 'Item Variation',
      // "allergen": "Allergen",
      modifier_set: 'Modifier Set',
      tax: 'Tax',
    },
  ];

  const { CatalogList, items_sub_list, ItemsList } = useSelector(
    (state: any) => state.catalogDetails
  );
  const [categories, setCategories] = useState(
    CatalogList?.categoriesList || []
  );

  const { ModifierList } = useSelector((state: any) => state.modifierDetails);
  const { HotSellingList, HotSellingStatus } = useSelector(
    (state: any) => state.hotSellingListDetails
  );
  const { UpSellingList } = useSelector(
    (state: any) => state.upSellingListDetails
  );
  const { CrossSellingList } = useSelector(
    (state: any) => state.crossSellingListDetails
  );
  const { HotSellingDrp } = useSelector(
    (state: any) => state.hotSellingDrpDetails
  );
  const { ItemTagEditName } = useSelector(
    (state: any) => state.itemsListDetails
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [menuItems, setmenuItems] = React.useState(false);
  const [hide, setHide] = React.useState(true);
  const [categoryHide, setCategoryHide] = React.useState(false);
  const [listHide, setListHide]: any = React.useState(false);
  const [variationHide, setVariationHide] = React.useState(false);
  const [tagHide, setTagHide] = React.useState(false);
  const [category, setcategory] = React.useState(false);
  const [itemdesc, setItemDesc] = React.useState(false);
  const [editAllergen, setEditAllergen] = React.useState(false);
  const [editHotSelling, setEditHotSelling] = React.useState(false);
  const [editUpSelling, setEditUpSelling] = React.useState(false);
  const [editCrossSelling, setEditCrossSelling] = React.useState(false);
  const [editCategory, setEditCategory] = React.useState(false);
  const [editItem, setEditItem] = React.useState(false);
  const [editVariation, setEditVariation] = React.useState(false);
  const [editmodifierSet, setEditmodifierSet] = React.useState(false);
  const [categoryNameTab, setCategoryNameTab] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [itemsListArray, setItemsListArray]: any = useState([]);
  const [selectedCategory, setSelectedCategory]: any = useState([]);
  const [categoryState, setCategoryState]: any = useState({});
  const [itemVariationsList, setItemVarationsList]: any = useState([]);
  const [selectedItemVariationId, setSelectedItemVariationId]: any =
    useState('');
  const [expanded, setExpanded] = React.useState(false);
  const { menu_name, menu_code }: any = useParams();
  const [expandedItems, setExpandedItems] = React.useState(false);
  const [taxHide, setTaxHide] = useState(false);
  const [modifierHide, setModifierHide] = useState(false);
  const [categoryPosId, setCategoryPosId]: any = useState(null);
  const [selectedImage, setSelectedImage]: any = useState<File | null>(null);
  const [itemNameEdit, setItemNameEdit]: any = useState('');
  const [kimStock, setKimStock]: any = useState('');
  const [timings, setTimings]: any = useState({});
  const [modifierIdData, setModifierIdData] = useState('');
  const [itemState, setItemState]: any = useState({});
  const [crosssellingHide, setCrosssellingHide] = useState(false);
  const [taxData, setTaxData]: any = useState('');
  const [token, setToken] = useState('');
  const location = useLocation();
  const {
    location_id,
    menu_QR_code_data,
    location_name,
    time,
    menu_id_data,
    hot_selling_status,
  } = location.state;
  const [hotSellingDropDown, setHotSellingDropDown]: any = useState([]);
  const [upSellingDropDown, setUpSellingDropDown]: any = useState([]);
  const [crossSellingDropDown, setCrossSellingDropDown]: any = useState([]);
  const [selectedItems, setSelectedItems]: any = useState([]);
  const [selectedModifierItem, setSelectedModifierItem]: any = useState([]);
  const [editHotSellingModal, seteditHotSellingModal] = React.useState(false);
  const [editUpSellingModal, seteditUpSellingModal] = React.useState(false);
  const [editCrossSellingModal, seteditCrossSellingModal] =
    React.useState(false);
  const [partialHide, setPartialHide]: any = useState([]);
  const [isCategoryOpens, setIsCategoryOpens]: any = useState([]);
  const [isItemsOpens, setIsItemsOpens]: any = useState([]);
  const [editCategoryObject, setEditCategoryObject]: any = useState({});
  const [editItemObject, setEditItemObject]: any = useState({});
  const [editAlergencySelect, setEditAlergencySelect]: any = useState({});
  const [tagView, setTagView]: any = useState(false);
  const [allergenView, setAllergenView]: any = useState(false);
  const [upSellingData, setUpSellingData]: any = useState([]);
  const [crossSellingData, setCrossSellingData] = useState([]);
  const [schedule, setSchedule]: any = useState({});
  const [globalItemId, setGlobalItemId]: any = useState(null);
  const [globalItemNameId, setGlobalItemNameId]: any = useState(null);
  const [globalCategoryPosId, setGlobalCategoryPosId]: any = useState(null);
  const [tagEditModal, setTagEditModal] = useState(false);
  const [editHotsSellingStatus, setEditHotsSellingStatus]: any = useState();
  const [previewImage, setPreviewImage]: any = useState(null);
  const [selectedHotSellingItems, setSelectedHotSellingItems]: any = useState(
    []
  );
  const [selectedUpSellingItems, setSelectedUpSellingItems]: any = useState([]);
  const [selectedCrossSellingItems, setSelectedCrossSellingItems]: any =
    useState([]);
  const [upSellingStatus, setUpSellingStatus]: any = useState();
  const [crossSellingStatus, setCrossSellingStatus]: any = useState();
  const [allergenStatus, setAllergenStatus]: any = useState();
  const [itemallergenStatus, setItemAllergenStatus]: any = useState();
  const [modifierStatus, setModifierStatus]: any = useState();
  const [variation_status, setVariationStatus]: any = useState();
  const [globalVariationId, setGlobalVariationId] = useState(null);
  const [loadingStatusChange, setLoadingStatusChange] = useState(false);
  const [loadingStatusChangeItems, setLoadingStatusChangeItems]: any = useState(
    {}
  );
  const [loadingStatusChangeCategory, setLoadingStatusChangeCategory]: any =
    useState({});
  const [loadingStatusItemsCategory, setLoadingStatusItemsCategory]: any =
    useState({});
  const [loadingvariationStatus, setLoadingvariationStatus]: any = useState({});
  const [loadingallergenStatus, setLoadingallergenStatus]: any = useState({});
  const [loadingitemalleregenStatus, setLoadingitemallergenStatus]: any =
    useState({});
  const [loadingUpsellingStatus, setLoadingUpsellingStatus]: any = useState({});
  const [loadingCrossSellingStatus, setLoadingCrossSellingStatus]: any =
    useState({});
  const [loadingItemvariationStatus, setLoadingItemvariationStatus]: any =
    useState({});
  const [loadingmodifierStatus, setLoadingmodifierStatus]: any = useState({});
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [upsellingLoading, setUpsellingLoading] = useState(true);
  const [crossSllingLoading, setCrossSllingLoading] = useState(false);
  const [editTagname, setEditTagname] = useState(
    items_sub_list?.tags?.[0]?.name
  );

  useEffect(() => {
    setEditTagname(items_sub_list?.tags?.[0]?.name);
  }, [items_sub_list]);

  const [specialRequests, setSpecialRequests] = useState({
    item_quantities_instructions: '',
    special_request_instructions: '',
  });

  const orderOptionModel = {
    dine_in: false,
    take_out: false,
    both: false,
  };

  const [orderOptions, setOrderOptions]: any = useState(orderOptionModel);

  useEffect(() => {
    const TokenData: any = localStorage.getItem('Token');
    setToken(TokenData);
    AuthTokenId = TokenData;
  }, []);

  useEffect(() => {
    let hotSellingIds = HotSellingList?.map((elem: any) => elem?.id);
    setSelectedHotSellingItems(
      HotSellingDrp?.filter((elem: any) => hotSellingIds.includes(elem?.id))
    );
  }, [HotSellingList, HotSellingDrp]);

  useEffect(() => {
    let crossSellingIds = CrossSellingList?.map((elem: any) => elem?.id);
    setSelectedCrossSellingItems(
      HotSellingDrp?.filter((elem: any) => crossSellingIds.includes(elem?.id))
    );
  }, [CrossSellingList, HotSellingDrp]);

  useEffect(() => {
    let crossSellingIds = UpSellingList?.map((elem: any) => elem?.id);
    setSelectedUpSellingItems(
      HotSellingDrp?.filter((elem: any) => crossSellingIds.includes(elem?.id))
    );
  }, [UpSellingList, HotSellingDrp]);

  const handleCheckboxChange = (allergenId: string) => {
    // Find the allergen object corresponding to the given id
    const allergenIndex = editItemObject?.all_allergens.findIndex(
      (allergen: any) => allergen.id === allergenId
    );
    if (allergenIndex === -1) {
      return; // Handle invalid allergenId
    }
    const updatedAllergens = [...editItemObject.all_allergens];
    updatedAllergens[allergenIndex].status =
      !updatedAllergens[allergenIndex].status;
    const selectedCount = updatedAllergens.filter(
      (allergen) => allergen.status
    ).length;

    if (selectedCount > 3) {
      // Revert the status change
      updatedAllergens[allergenIndex].status =
        !updatedAllergens[allergenIndex].status;
      setEditItemObject((prevItemObject: any) => ({
        ...prevItemObject,
        all_allergens: updatedAllergens,
      }));
      // Display a message to the user
      enqueueSnackbar('You can only select up to three allergens.', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        style: { fontFamily: 'Poppins', fontWeight: '500' },
      });
      return;
    }

    // Update the state with the modified allergens array
    setEditItemObject((prevItemObject: any) => ({
      ...prevItemObject,
      all_allergens: updatedAllergens,
    }));
  };

  const handleOrderOptionChange = (option: string) => {
    setOrderOptions((prevOptions: any) => {
      // if (option === "both") {
      // 	const bothSelected = !prevOptions.both;
      // 	return {
      // 		dine_in: bothSelected,
      // 		take_out: bothSelected,
      // 		both: bothSelected,
      // 	};
      // } else {
      // 	return {
      // 		...prevOptions,
      // 		[option]: !prevOptions[option],
      // 	};
      // }
      return {
        ...orderOptionModel,
        [option]: !prevOptions[option],
      };
    });
  };

  const toggleAccordion = () => {
    setExpanded(!expanded);
    setmenuItems(!menuItems);
    setHide(!hide);
    setmenuItems(!menuItems);
  };

  useEffect(() => {
    const UserCode = localStorage.getItem('UserCode');
    UserCodeData = UserCode;
  }, []);

  const handleChangeUpselling = (event: any) => {
    let data = event.target.value;
    setSelectedUpSellingItems([...data]);
  };

  const handleChangeCrossSelling = (event: any) => {
    let data = event.target.value;
    setSelectedCrossSellingItems([...data]);
  };

  const handleChangeHotSelling = (filteredSelectedItems: any) => {
    console.log(filteredSelectedItems, 'filteredSelectedItems');
    const selectedIds = filteredSelectedItems.map(
      (option: any) => option.value
    );
    setSelectedHotSellingItems(filteredSelectedItems);
    // setSelectedHotSellingItems(selectedIds);
  };

  const HotSellingModal = () => {
    setEditHotSelling(true);
  };

  const BasicCatalogData = async () => {
    const headers = {
      Authorization: `Bearer ${AuthTokenId}`,
    };
    try {
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
          ApiServices.catalog_list +
          UserCodeData +
          '/' +
          menu_code,
        headers
      );
      if (response.status === true) {
        BasicListData = response.taxes.data;
        const ModifierListID = response.modifiers_list?.map(
          (modifierID: any) => modifierID.modifier_list_id
        );
        for (const modifier_ID of ModifierListID) {
          await ModifierDataItems(setModifierIdData);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const MenusDataItems = async () => {
    const headers = {
      Authorization: `Bearer ${AuthTokenId}`,
    };
    try {
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
          ApiServices.category_list +
          `?menu_code=${menu_code}&user_code=${UserCodeData}&device_type=vendor_web`,
        headers
      );
      if (response.status === true) {
        dispatch(setCatalogList(response));
        const posIds = response.categoriesList?.map(
          (category: any) => category.pos_id
        );
      } else {
        console.log('Error');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const ModifierDataItems = async (modifierIdData: any) => {
    const headers = {
      Authorization: `Bearer ${AuthTokenId}`,
    };
    try {
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
          ApiServices.modifiers_list +
          `?menu_code=${menu_code}&user_code=${UserCodeData}&modifier_list_id=${modifierIdData}`,
        headers
      );
      if (response.status === true) {
        dispatch(setModifierList(response.modifiers));
      } else {
        console.log('Error');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const ItemsListData = async (categoryPosId: any) => {
    setCategoryLoading(true);
    const headers = {
      Authorization: `Bearer ${AuthTokenId}`,
    };
    try {
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
          ApiServices.items_list +
          `?menu_code=${menu_code}&user_code=${UserCodeData}&category_id=${categoryPosId}&device_type=vendor_web`,
        headers
      );
      if (response.status === true) {
        setItemsListArray(response.items);
        setItemsList(response.items);
        setModifierIdData(response.items[0].modifier_list_id);
        const ItemVariationId = response?.items?.map(
          (itemVariationList: any) => itemVariationList.item_id
        );
        setSelectedItemVariationId(ItemVariationId[0]);
        setCategoryLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const ItemsListSubData = async (itemId: any) => {
    const headers = {
      Authorization: `Bearer ${AuthTokenId}`,
    };
    try {
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
          ApiServices.items_list +
          '/' +
          `${itemId}?menu_code=${menu_code}&user_code=${UserCodeData}&device_type=vendor_web`,
        headers
      );
      if (response.status === true) {
        dispatch(setItemsSubList(response.item));
        setUpSellingData(response.item.up_selling_items);
        // dispatch(setCrossSellingList(response.item.cross_selling_items));
        // dispatch(setUpSellingList(response.item.up_selling_items));
        setCrossSellingData(response.item.cross_selling_items);
        console.log('::::::::::+++++++');
        setAllergenStatus(response.item.allergen_status);
        setItemAllergenStatus(response.item.item_allergens[0].pivot.status);
        console.log(
          response.item.item_allergens[0].pivot.status,
          'allergenStatus{}{}{}{}'
        );
        setUpSellingStatus(response.item.upselling_status);
        setCrossSellingStatus(response.item.crossselling_status);
        setModifierStatus(response.item.modifier_status);
        setVariationStatus(response.item.variation_status);
      } else {
        console.log('Error');
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const ItemVariationsList = async (item_id: any) => {
    const headers = {
      Authorization: `Bearer ${AuthTokenId}`,
    };
    try {
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
          ApiServices.item_variations +
          `?item_id=${globalItemNameId}&user_code=${UserCodeData}&menu_code=${menu_code}&device_type=vendor_web`,
        headers
      );
      if (response.status === true) {
        setItemVarationsList(response.items);
        return response?.items?.map((itemsList: any) => itemsList.category_id);
      } else {
        console.log('Error');
        return [];
      }
    } catch (error) {
      console.log(error);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const handleCategorySelection = (categoryPosId: any) => {
    setCategoryLoading(true);
    const idExists = selectedCategory.includes(categoryPosId);
    if (idExists) {
      setSelectedCategory(
        selectedCategory.filter((elem: any) => elem !== categoryPosId)
      );
      setExpandedItems(false);
    } else {
      setSelectedCategory([categoryPosId]);
      setExpandedItems(true);
      setcategory(true);
      setCategoryHide(true);
      setCategoryNameTab(true);
      ItemsListData(categoryPosId);
      setCategoryState((prevState: any) => ({
        ...prevState,
        [categoryPosId]: !prevState[categoryPosId],
      }));
    }
  };

  const HandleNameCategoryClose = (categoryPosId: any) => {
    const idExists = selectedCategory.includes(categoryPosId);
    if (idExists) {
      setSelectedCategory(
        selectedCategory.filter((elem: any) => elem !== categoryPosId)
      );
      setExpandedItems(!expandedItems);
      setItemsListArray([]);
      setcategory(!category);
      setCategoryHide(!categoryHide);
      setCategoryNameTab(!categoryNameTab);
      setCategoryState((prevState: any) => ({
        ...prevState,
        [categoryPosId]: !prevState[categoryPosId],
      }));
      setSelectedCategory(
        selectedCategory.filter((elem: any) => elem != categoryPosId)
      );
      setIsCategoryOpens(
        isCategoryOpens.filter((elem: any) => elem != categoryPosId)
      );
    }
    setIsCategoryOpens(
      isCategoryOpens.filter((elem: any) => elem !== categoryPosId)
    );
  };

  const handleItemClose = (itemId: any) => {
    setIsItemsOpens(isItemsOpens.filter((elem: any) => elem != itemId));
    setListHide({ [itemId]: false });
    setItemDesc(false);
    setItemState((prevState: any) => ({
      ...prevState,
      [itemId]: !prevState[itemId],
    }));
  };

  useEffect(() => {
    MenusDataItems();
    BasicCatalogData();
  }, [menu_code, UserCodeData]);

  useEffect(() => {}, [itemsListArray]);

  const EditCategoryModal = (category: any) => {
    setEditCategory(true);
    setEditCategoryObject(category);
    setCategoryPosId(category.pos_id);
    setSelectedImage(null);
  };

  const EditItemName = async (item_id: any) => {
    const editEditId = editItemObject?.id;
    const selectedAllergenIds = editItemObject?.all_allergens
      ?.filter((allergen: any) => allergen.status)
      .map((allergen: any) => allergen.id);
    // if (selectedAllergenIds.length === 0) {
    // 	enqueueSnackbar("Please select allergens", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, style: { fontFamily: "Poppins", fontWeight: "500" }, });
    // 	return;
    // }

    const formData = new FormData();
    formData.append('item_name', editItemObject?.name);
    formData.append('status', editItemObject?.status);
    formData.append('menu_code', menu_code);
    formData.append('user_code', UserCodeData);
    formData.append('item_id', item_id);
    formData.append('location_id', 'L7K7PZD6SV0ND');
    formData.append('stock', kimStock);
    formData.append('order_type', JSON.stringify(orderOptions));
    selectedAllergenIds.forEach((id: string) => {
      formData.append('allergen_ids[]', id); // Append each selected allergen ID
    });
    formData.append('description', editItemObject?.description);
    formData.append('allow_special', editItemObject?.allow_special ? '1' : '0');
    const scheduleKeys = Object.keys(schedule);
    scheduleKeys.forEach((elem: any) => {
      formData.append(elem, JSON.stringify(schedule[elem]));
    });
    formData.append('special_requests', JSON.stringify(specialRequests));
    if (previewImage) {
      formData.append('image', previewImage);
    }

    try {
      setLoading(true);
      const response = await HelperModule().postFormDataMethod(
        ApiServices.base_URL + ApiServices.edit_item_name,
        formData
      );
      setLoading(false);
      if (response.status === true) {
        setEditItem(false);
        enqueueSnackbar('Item Updated Successfully', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          style: { fontFamily: 'Poppins', fontWeight: '500' },
        });
        ItemsListData(globalCategoryPosId);
      } else {
        enqueueSnackbar('Unable to update', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          style: { fontFamily: 'Poppins', fontWeight: '500' },
        });
        console.log('Error');
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar('Unable to update', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        style: { fontFamily: 'Poppins', fontWeight: '500' },
      });
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const GetHotSellingData = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${AuthTokenId}`,
      };
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
          ApiServices.get_hot_selling_data +
          UserCodeData +
          '/' +
          menu_code,
        headers
      );
      if (response.status === true) {
        dispatch(setHotSellingList(response.items));
      } else {
        console.log('Error Response', response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (UpSellingList && UpSellingList.length > 0) {
      setUpsellingLoading(false);
    }
  }, [UpSellingList]);

  useEffect(() => {
    if (CrossSellingList && CrossSellingList.length > 0) {
      setCrossSllingLoading(false);
    }
  }, [CrossSellingList]);

  const GetTaxData = async () => {
    const headers = {
      Authorization: `Bearer ${AuthTokenId}`,
    };
    try {
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
          ApiServices.tax +
          `?menu_code=${menu_code}&user_code=${UserCodeData}`,
        headers
      );
      if (response.status === true) {
        setTaxData(response.data[0]);
      } else {
        console.log('Error Response', response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetHotSellingDropDown = async () => {
    const headers = {
      Authorization: `Bearer ${AuthTokenId}`,
    };
    try {
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
          ApiServices.hot_selling_edit_drop_down +
          '/' +
          menu_code,
        headers
      );
      if (response.status === true) {
        dispatch(setHotSellingDrp(response.items));
      } else {
        console.log('Error Response', response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const HotSellingChangeStatus = async () => {
    try {
      const response = await HelperModule().getMethod(
        ApiServices.base_URL + ApiServices.hot_selling_status + `${menu_code}`
      );
      if (response.status === true) {
        setEditHotsSellingStatus(response.data.hot_selling_status);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    HotSellingChangeStatus();
  }, []);

  const changeStatus = async (type: any, id: any, allergen_id: any) => {
    const params = { type, id, allergen_id };
    let timeoutId: any;
    switch (type) {
      case 'hot_selling':
        setLoadingStatusChange(true);
        break;
      case 'item_hot_selling':
        setLoadingStatusChangeItems((prevState: any) => ({
          ...prevState,
          [id]: true,
        }));
        break;
      case 'categories':
        setLoadingStatusChangeCategory((prevState: any) => ({
          ...prevState,
          [id]: true,
        }));
        break;
      case 'items':
        setLoadingStatusItemsCategory((prevState: any) => ({
          ...prevState,
          [id]: true,
        }));
        break;
      case 'variation':
        setLoadingvariationStatus((prevState: any) => ({
          ...prevState,
          [id]: true,
        }));
        break;
      case 'allergen':
        setLoadingallergenStatus((prevState: any) => ({
          ...prevState,
          [id]: true,
        }));
        break;
      case 'item_allergens':
        setLoadingitemallergenStatus((prevState: any) => ({
          ...prevState,
          [id]: true,
        }));
        break;
      case 'upselling':
        setLoadingUpsellingStatus((prevState: any) => ({
          ...prevState,
          [id]: true,
        }));
        break;
      case 'crossselling':
        setLoadingCrossSellingStatus((prevState: any) => ({
          ...prevState,
          [id]: true,
        }));
        break;
      case 'item_modifier':
        setLoadingmodifierStatus((prevState: any) => ({
          ...prevState,
          [id]: true,
        }));
        break;
      case 'item_variations':
        setLoadingItemvariationStatus((prevState: any) => ({
          ...prevState,
          [id]: true,
        }));
        break;
      default:
        break;
    }
    try {
      const response = await HelperModule().postMethod(
        ApiServices.base_URL + ApiServices.changeStatus,
        params
      );
      if (response.status === true) {
        switch (type) {
          case 'hot_selling':
            HotSellingChangeStatus();
            setLoadingStatusChange(false);
            enqueueSnackbar(response.message, {
              variant: 'success',
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
              style: { fontFamily: 'Poppins', fontWeight: '500' },
            });
            break;
          case 'item_hot_selling':
            GetHotSellingData();
            timeoutId = setTimeout(() => {
              setLoadingStatusChangeItems((prevState: any) => ({
                ...prevState,
                [id]: false,
              }));
              enqueueSnackbar(response.message, {
                variant: 'success',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                style: { fontFamily: 'Poppins', fontWeight: '500' },
              });
            }, 1000);
            setLoadingStatusChangeItems((prevState: any) => ({
              ...prevState,
              [id]: timeoutId,
            }));
            break;
          case 'categories':
            MenusDataItems();
            timeoutId = setTimeout(() => {
              setLoadingStatusChangeCategory((prevState: any) => ({
                ...prevState,
                [id]: false,
              }));
              enqueueSnackbar(response.message, {
                variant: 'success',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                style: { fontFamily: 'Poppins', fontWeight: '500' },
              });
            }, 2000);

            setLoadingStatusChangeCategory((prevState: any) => ({
              ...prevState,
              [id]: timeoutId,
            }));
            break;
          case 'items':
            ItemsListData(globalCategoryPosId);
            timeoutId = setTimeout(() => {
              setLoadingStatusItemsCategory((prevState: any) => ({
                ...prevState,
                [id]: false,
              }));
              enqueueSnackbar(response.message, {
                variant: 'success',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                style: { fontFamily: 'Poppins', fontWeight: '500' },
              });
            }, 1000);

            setLoadingStatusItemsCategory((prevState: any) => ({
              ...prevState,
              [id]: timeoutId,
            }));
            break;
          case 'variation':
            ItemsListSubData(globalItemId);
            timeoutId = setTimeout(() => {
              setLoadingvariationStatus((prevState: any) => ({
                ...prevState,
                [id]: false,
              }));
              enqueueSnackbar(response.message, {
                variant: 'success',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                style: { fontFamily: 'Poppins', fontWeight: '500' },
              });
            }, 1000);
            setLoadingvariationStatus((prevState: any) => ({
              ...prevState,
              [id]: timeoutId,
            }));
            break;
          case 'allergen':
            ItemsListSubData(globalItemId);
            timeoutId = setTimeout(() => {
              setLoadingallergenStatus((prevState: any) => ({
                ...prevState,
                [id]: false,
              }));
              enqueueSnackbar(response.message, {
                variant: 'success',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                style: { fontFamily: 'Poppins', fontWeight: '500' },
              });
            }, 1000);
            setLoadingallergenStatus((prevState: any) => ({
              ...prevState,
              [id]: timeoutId,
            }));
            break;
          case 'item_allergens':
            ItemsListSubData(globalItemId);
            timeoutId = setTimeout(() => {
              setLoadingitemallergenStatus((prevState: any) => ({
                ...prevState,
                [id]: false,
              }));
              enqueueSnackbar(response.message, {
                variant: 'success',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                style: { fontFamily: 'Poppins', fontWeight: '500' },
              });
            }, 1000);
            setLoadingitemallergenStatus((prevState: any) => ({
              ...prevState,
              [id]: timeoutId,
            }));
            break;
          case 'upselling':
            ItemsListSubData(globalItemId);
            timeoutId = setTimeout(() => {
              setLoadingUpsellingStatus((prevState: any) => ({
                ...prevState,
                [id]: false,
              }));
              enqueueSnackbar(response.message, {
                variant: 'success',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                style: { fontFamily: 'Poppins', fontWeight: '500' },
              });
            }, 1000);
            setLoadingUpsellingStatus((prevState: any) => ({
              ...prevState,
              [id]: timeoutId,
            }));
            break;
          case 'crossselling':
            ItemsListSubData(globalItemId);
            timeoutId = setTimeout(() => {
              setLoadingCrossSellingStatus((prevState: any) => ({
                ...prevState,
                [id]: false,
              }));
              enqueueSnackbar(response.message, {
                variant: 'success',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                style: { fontFamily: 'Poppins', fontWeight: '500' },
              });
            }, 1000);
            setLoadingCrossSellingStatus((prevState: any) => ({
              ...prevState,
              [id]: timeoutId,
            }));
            break;
          case 'item_modifier':
            ItemsListSubData(globalItemId);
            timeoutId = setTimeout(() => {
              setLoadingmodifierStatus((prevState: any) => ({
                ...prevState,
                [id]: false,
              }));
              enqueueSnackbar(response.message, {
                variant: 'success',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                style: { fontFamily: 'Poppins', fontWeight: '500' },
              });
            }, 1000);
            setLoadingmodifierStatus((prevState: any) => ({
              ...prevState,
              [id]: timeoutId,
            }));
            break;
          case 'item_variations':
            ItemVariationsList(globalVariationId);
            timeoutId = setTimeout(() => {
              setLoadingItemvariationStatus((prevState: any) => ({
                ...prevState,
                [id]: false,
              }));
              enqueueSnackbar(response.message, {
                variant: 'success',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                style: { fontFamily: 'Poppins', fontWeight: '500' },
              });
            }, 1000);
            setLoadingItemvariationStatus((prevState: any) => ({
              ...prevState,
              [id]: timeoutId,
            }));
            break;
          default:
            // MenusDataItems();
            break;
        }
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const AddHotDrpData = async () => {
    const params = {
      user_code: UserCodeData,
      menu_code: menu_code,
      item_ids: selectedHotSellingItems.map((obj: { id: any }) => obj.id),
    };
    try {
      const response = await HelperModule().postFormDataMethod(
        ApiServices.base_URL + ApiServices.add_hot_selling_items,
        params
      );
      setHotSellingDropDown(response.data);
      if (response.status === true) {
        setTimeout(() => {
          seteditHotSellingModal(true);
        }, 100);
      }
    } catch (error) {
      console.log(error);
    }
    setEditHotSelling(false);
  };

  const AddUpDrpData = async (item_id: any) => {
    setLoading(true);
    const params = {
      up_selling_ids: selectedUpSellingItems.map((obj: { id: any }) => obj.id),
      item_id: globalItemId,
    };
    try {
      const response = await HelperModule().postFormDataMethod(
        ApiServices.base_URL + ApiServices.add_up_selling_items,
        params
      );
      setUpSellingDropDown(response.data);
      if (response.status === true) {
        setTimeout(() => {
          seteditUpSellingModal(true);
          setLoading(false);
          setTagHide(false);
        }, 100);
        setLoading(false);
      } else if (response.status === false) {
        setLoading(false);
        enqueueSnackbar(response.message, {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          style: { fontFamily: 'Poppins', fontWeight: '500' },
        });
      }
    } catch (error) {
      console.log(error);
    }
    setEditUpSelling(false);
  };

  const AddCrossDrpData = async (item_id: any) => {
    setEditCrossSelling(true);
    const params = {
      item_id: globalItemId,
      cross_selling_ids: selectedCrossSellingItems.map(
        (obj: { id: any }) => obj.id
      ),
    };
    try {
      const response = await HelperModule().postFormDataMethod(
        ApiServices.base_URL + ApiServices.add_cross_selling_items,
        params
      );
      setCrossSellingDropDown(response.data);
      console.log(response.data, 'data----- Croselling Data');
      if (response.status === true) {
        setTimeout(() => {
          seteditCrossSellingModal(true);
          setCrosssellingHide(false);
        }, 100);
      } else if (response.status === false) {
        setEditCrossSelling(false);
        enqueueSnackbar(response.message, {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          style: { fontFamily: 'Poppins', fontWeight: '500' },
        });
      }
    } catch (error) {
      console.log(error);
    }
    setEditCrossSelling(false);
  };

  useEffect(() => {
    GetHotSellingData();
    GetTaxData();
    GetHotSellingDropDown();
  }, []);

  const downloadQR = async () => {
    const response = await fetch(
      `https://api.miniosk.com/api/downloadQrCode/${menu_code}`
    );
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'qr_code.png';
    link.click();
  };

  const RearrangeCategories = async (id: any, newPosition: any, type: any) => {
    setLoading(true);
    let params = {};
    if (type === 'category') {
      params = {
        category_id: id,
        rearrange_type: type,
        position: newPosition,
      };
    } else {
      params = {
        item_id: id,
        rearrange_type: type,
        position: newPosition,
      };
    }
    try {
      const response = await HelperModule().postMethod(
        ApiServices.base_URL + ApiServices.rearrage_categorires,
        params
      );
      if (response.status === true) {
        setLoading(false);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleTagNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const truncatedValue = inputValue.slice(0, 12);
    // dispatch(setItemTagEditName(truncatedValue));
    setEditTagname(truncatedValue);
  };

  const TagNameEdit = async () => {
    const params = {
      tag: editTagname,
    };
    try {
      const response = await HelperModule().postMethod(
        ApiServices.base_URL + ApiServices.tag_edit + globalItemId,
        params
      );
      if (response.status === true) {
        enqueueSnackbar(response.message, {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          style: { fontFamily: 'Poppins', fontWeight: '500' },
        });
        setTagEditModal(false);
        handleItemClose(globalItemId);
        dispatch(setItemTagEditName(''));
      } else {
        enqueueSnackbar(response.message, {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          style: { fontFamily: 'Poppins', fontWeight: '500' },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const subscribeChannel = () => {
    try {
      echoInstance.channel('miniosk').listen('.soldout', (e: any) => {
        if (e.type === 'CATEGORY') {
          MenusDataItems();
          console.log('ITEM UPDATE');
        } else if (e.type === 'ITEM') {
          MenusDataItems();
          ItemsListData(globalCategoryPosId);
          console.log(e.type, 'type>>>>>>');
          console.log(globalCategoryPosId, '::globalCategoryPosId::');
        }
      });
      console.log('Subscribed to channel successfully.');
    } catch (error) {
      console.error('Error subscribing to channel:', error);
    }
  };

  const disconnectEcho = () => {
    try {
      if (echoInstance) {
        echoInstance.disconnect();
        echoInstance = null;
        console.log('Echo disconnected successfully.');
      } else {
        console.log('Echo is not connected.');
      }
    } catch (error) {
      console.error('Error disconnecting Echo:', error);
    }
  };

  const checkConnectionState = () => {
    try {
      if (
        echoInstance &&
        echoInstance.connector &&
        echoInstance.connector.socket
      ) {
        const isConnected = echoInstance.connector.socket.connected;
        console.log(
          'Connection state:',
          isConnected ? 'Connected' : 'Disconnected'
        );
      } else {
        console.log('Echo connection not initialized or socket not available.');
      }
    } catch (error) {
      console.error('Error checking connection state:', error);
    }
  };

  useEffect(() => {
    connectEcho();
    subscribeChannel();
    checkConnectionState();
    return () => {
      disconnectEcho();
      checkConnectionState();
    };
  }, [globalCategoryPosId]);

  const onDragEnd = async (result: any) => {
    if (result.type === 'category') {
      if (!result.destination) {
        return;
      }

      const { source, destination } = result;

      if (!source || !destination || source.index === destination.index) {
        return;
      }

      const categoryId = CatalogList?.categoriesList[source.index]?.id;
      const newPosition = destination.index + 1;

      // Optimistically update the UI
      const reorderedCategories = Array.from(categories);
      const [removed] = reorderedCategories.splice(source.index, 1);
      reorderedCategories.splice(destination.index, 0, removed);
      setCategories(reorderedCategories);

      try {
        setLoading(true);
        await RearrangeCategories(categoryId, newPosition, 'category');
        // setExpandedItems(!expandedItems);
      } catch (error) {
        console.error('Error:', error);
        await setCategories(categories);
      } finally {
        setLoading(false);
      }
    } else {
      console.log(result, 'else');
      const { source, destination } = result;

      if (!source || !destination || source.index === destination.index) {
        return;
      }
      const itemId = itemsListArray[source.index].id;
      const newPosition = destination.index + 1;

      // Optimistically update the UI
      const reorderedItems = [...itemsListArray];
      const [removed] = reorderedItems.splice(source.index, 1);
      reorderedItems.splice(destination.index, 0, removed);
      setItemsListArray(reorderedItems);
      try {
        setLoading(true);
        await RearrangeCategories(itemId, newPosition, 'item');
        // setExpandedItems(!expandedItems);
      } catch (error) {
        console.error('Error:', error);
        await setItemsListArray(itemsListArray);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      {isLoading ? (
        <div className="Loader-container">
          <CircularProgress disableShrink sx={{ color: '#8B5CFF' }} />
        </div>
      ) : (
        <>
          <BackNavigation label={'Back'} />
          <div className="container">
            <h1 className="menu-id">{menu_name.toUpperCase()}</h1>
            <p className="desc">{location_name}</p>
            <p className="desc">{time}</p>
            <img
              src={menu_QR_code_data}
              alt="qr"
              style={{ width: '200px', marginTop: '24px' }}
            />
            <button
              className="download-qr"
              onClick={downloadQR}
              style={{ transition: 'background-color 0.3s', cursor: 'pointer' }}
              onMouseEnter={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor =
                  '#6d43e57D';
              }}
              onMouseLeave={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor =
                  '#8B5CFF';
              }}
            >
              <img
                src={Download}
                alt="Download"
                style={{ height: '12px', width: '8px', marginRight: '10px' }}
              />
              Download QR
            </button>
            <h3 className="content-heading">Content</h3>
            <p className="content-desc">
              {' '}
              All of your menu data is shown below. You can expand an entry to
              see its children (items, modifiers, etc). Use the drag indicatoron
              the left hand side of an entry to change the order that it appears
              on your miniosks. Use the action buttons on the right handside to
              show, hide, edit, or delete entries.{' '}
            </p>
            <div className="menu-item-container">
              <Accordion
                expanded={expanded}
                style={{ borderRadius: '16px', width: '52.34vw', opacity: '1' }}
              >
                <AccordionSummary>
                  <Grid container className="summaryContent">
                    <Grid item>
                      <div className="hot-selling" style={{ height: '40px' }}>
                        {hide ? (
                          <>
                            {/* <img src={Dots} style={{ height: "24px", width: "24px" }} alt="dots" /> */}
                            <h3 className="hot-selling-header">
                              Hot Selling🔥{' '}
                            </h3>
                          </>
                        ) : (
                          <h3 className="hot-selling-header">Hot Selling🔥</h3>
                        )}
                      </div>
                    </Grid>
                    <Grid item>
                      <div className="operate-container">
                        {hide ? (
                          <button
                            onClick={toggleAccordion}
                            className="show-btn"
                            style={{
                              backgroundColor: '#FFFFFF',
                              cursor: 'pointer',
                            }}
                          >
                            <img
                              src={ArrowDown}
                              alt="down"
                              style={{
                                height: '20px',
                                width: '20px',
                                marginRight: '10px',
                              }}
                            />
                            Show
                          </button>
                        ) : (
                          <button
                            onClick={toggleAccordion}
                            className="show-btn"
                            style={{
                              backgroundColor: '#FFFFFF',
                              cursor: 'pointer',
                            }}
                          >
                            <img
                              src={ArrowUp}
                              alt="up"
                              style={{
                                height: '20px',
                                width: '20px',
                                marginRight: '10px',
                              }}
                            />
                            Hide
                          </button>
                        )}
                        <button
                          onClick={() => HotSellingModal()}
                          className="show-btn"
                          style={{
                            backgroundColor: '#FFFFFF',
                            cursor: 'pointer',
                          }}
                        >
                          <img
                            src={Edit}
                            alt="edit"
                            style={{
                              height: '20px',
                              width: '20px',
                              marginRight: '10px',
                            }}
                          />
                          Edit
                        </button>
                        {loadingStatusChange ? (
                          <CircularProgress
                            size={20}
                            style={{
                              height: '20px',
                              width: '20px',
                              marginRight: '10px',
                              marginLeft: '16px',
                            }}
                            sx={{ color: '#8B5CFF' }}
                          />
                        ) : (
                          <>
                            {editHotsSellingStatus === 1 ? (
                              <div style={{ cursor: 'pointer' }}>
                                <img
                                  onClick={() =>
                                    changeStatus(
                                      'hot_selling',
                                      menu_id_data,
                                      null
                                    )
                                  }
                                  src={Eye}
                                  alt="edit"
                                  style={{
                                    height: '20px',
                                    width: '20px',
                                    marginRight: '10px',
                                    marginLeft: '16px',
                                    cursor: 'pointer',
                                  }}
                                />
                              </div>
                            ) : (
                              <img
                                onClick={() =>
                                  changeStatus(
                                    'hot_selling',
                                    menu_id_data,
                                    null
                                  )
                                }
                                src={HideEye}
                                alt="edit"
                                style={{
                                  height: '20px',
                                  width: '20px',
                                  marginRight: '10px',
                                  marginLeft: '16px',
                                  cursor: 'pointer',
                                }}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  {menuItems ? (
                    <>
                      <Divider />
                      <List>
                        {HotSellingList && HotSellingList?.length > 0 ? (
                          HotSellingList?.map((entry: any, index: any) => {
                            return (
                              <Grid
                                container
                                key={index}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  height: '6.48vh',
                                  flexDirection: 'column',
                                }}
                              >
                                <Grid
                                  item
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <div className="hot-selling">
                                    <img
                                      src={FoodBowl}
                                      style={{ height: '24px', width: '24px' }}
                                      alt="food-bowl"
                                    />
                                    <h2
                                      style={{
                                        margin: '0',
                                        paddingLeft: '10px',
                                        fontWeight: '600',
                                        fontSize: '14px',
                                        fontFamily: 'Poppins',
                                        color:
                                          entry.status === 1 ? '#000' : '#888',
                                      }}
                                    >
                                      {' '}
                                      {entry.name}
                                    </h2>
                                    {entry.recommended === 1 ? (
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          marginLeft: '10px',
                                          borderRadius: '4px',
                                          border: '1px solid #8B5CFF',
                                        }}
                                      >
                                        <h1
                                          style={{
                                            fontSize: '10px',
                                            margin: 0,
                                            padding: '2px',
                                            fontFamily: 'Poppins',
                                            fontWeight: 'bold',
                                            color: '#8B5CFF',
                                          }}
                                        >
                                          Recommended
                                        </h1>
                                      </div>
                                    ) : null}
                                  </div>
                                  <div
                                    style={{ marginRight: '10px' }}
                                    className="operate-container"
                                  >
                                    <h3
                                      style={{
                                        margin: '0',
                                        color:
                                          entry.status === 1
                                            ? '#8B5CFF'
                                            : '#888',
                                        fontWeight: '500',
                                        fontSize: '12px',
                                        fontFamily: 'Poppins',
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      {' '}
                                      ${' '}
                                      {entry.item_variation?.price_money
                                        .amount / 100 || 'N/A'}
                                    </h3>
                                    {loadingStatusChangeItems[entry.id] ? (
                                      <CircularProgress
                                        size={20}
                                        style={{
                                          height: '20px',
                                          width: '20px',
                                          marginRight: '10px',
                                          marginLeft: '16px',
                                        }}
                                        sx={{ color: '#8B5CFF' }}
                                      />
                                    ) : (
                                      <>
                                        {entry.status === 1 ? (
                                          <div style={{ cursor: 'pointer' }}>
                                            <img
                                              onClick={() =>
                                                changeStatus(
                                                  'item_hot_selling',
                                                  entry.id,
                                                  null
                                                )
                                              }
                                              src={Eye}
                                              alt="edit"
                                              style={{
                                                height: '20px',
                                                width: '20px',
                                                marginRight: '10px',
                                                marginLeft: '16px',
                                              }}
                                            />
                                          </div>
                                        ) : (
                                          <div style={{ cursor: 'pointer' }}>
                                            <img
                                              onClick={() =>
                                                changeStatus(
                                                  'item_hot_selling',
                                                  entry.id,
                                                  null
                                                )
                                              }
                                              src={HideEye}
                                              alt="edit"
                                              style={{
                                                height: '20px',
                                                width: '20px',
                                                marginRight: '10px',
                                                marginLeft: '16px',
                                              }}
                                            />
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </Grid>
                              </Grid>
                            );
                          })
                        ) : (
                          <List sx={{ mt: 0, p: 0 }}>
                            <div
                              className="hot-selling"
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                              }}
                            >
                              <h3
                                style={{
                                  fontSize: '12px',
                                  margin: '0px',
                                  fontFamily: 'Poppins',
                                  fontWeight: '600',
                                  color: 'gray',
                                }}
                              >
                                No Data Available, Meanwhile, Add Your Own
                                Hot-Selling Items
                              </h3>
                              <button
                                onClick={HotSellingModal}
                                style={{
                                  backgroundColor: '#8B5CFF',
                                  color: '#FFFFFF',
                                  padding: '5px',
                                  width: '120px',
                                  marginTop: '20px',
                                  border: '0px solid',
                                  borderRadius: '8px',
                                  transition: 'background-color 0.3s',
                                }}
                                onMouseEnter={(e) => {
                                  (
                                    e.target as HTMLButtonElement
                                  ).style.backgroundColor = '#6d43e57D';
                                }}
                                onMouseLeave={(e) => {
                                  (
                                    e.target as HTMLButtonElement
                                  ).style.backgroundColor = '#8B5CFF';
                                }}
                              >
                                Add Items
                              </button>
                            </div>
                          </List>
                        )}
                      </List>
                    </>
                  ) : null}
                </AccordionDetails>
              </Accordion>
            </div>
            <div></div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="categories" type="category">
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {CatalogList.categoriesList.map(
                      (category: any, index: number) => (
                        <Draggable
                          key={category.pos_id}
                          draggableId={category.pos_id}
                          index={index}
                          isDragDisabled={
                            selectedCategory.find(
                              (elem: any) => elem === category.pos_id
                            )
                              ? true
                              : false
                          }
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div
                                className="menu-item-container"
                                key={category.pos_id}
                              >
                                <Accordion
                                  expanded={expandedItems}
                                  style={{
                                    borderRadius: '16px',
                                    width: '52.34vw',
                                  }}
                                >
                                  <AccordionSummary>
                                    {!categoryNameTab ? (
                                      <div
                                        className="deails-row"
                                        style={{
                                          backgroundColor: '#FFFFFF',
                                          height: '40px',
                                        }}
                                      >
                                        <div className="hot-selling">
                                          <img
                                            alt="dots"
                                            src={Dots}
                                            style={{
                                              height: '24px',
                                              width: '24px',
                                            }}
                                          />
                                          <div className="category-container">
                                            <p
                                              className="category-desc"
                                              style={{ color: '#F34A4A' }}
                                            >
                                              Category
                                            </p>
                                            <h3 className="hot-selling-header">
                                              {' '}
                                              {category.name}{' '}
                                            </h3>
                                          </div>
                                        </div>
                                        <div className="operate-container">
                                          <button
                                            onClick={() => {
                                              let id = isCategoryOpens?.find(
                                                (elem: any) =>
                                                  elem == category.pos_id
                                              );
                                              let hide1 = partialHide?.find(
                                                (elem: any) =>
                                                  elem == category.pos_id
                                              );
                                              setIsCategoryOpens([
                                                category.pos_id,
                                              ]);
                                              handleCategorySelection(
                                                category.pos_id
                                              );
                                              setGlobalCategoryPosId(
                                                category.pos_id
                                              );
                                            }}
                                            className="show-btn"
                                            style={{
                                              backgroundColor: '#FFFFFF',
                                            }}
                                          >
                                            <img
                                              src={
                                                isCategoryOpens?.find(
                                                  (elem: any) =>
                                                    elem == category.pos_id
                                                )
                                                  ? ArrowUp
                                                  : ArrowDown
                                              }
                                              alt={
                                                isCategoryOpens?.find(
                                                  (elem: any) =>
                                                    elem == category.pos_id
                                                )
                                                  ? 'up'
                                                  : 'down'
                                              }
                                              style={{
                                                height: '20px',
                                                width: '20px',
                                                marginRight: '10px',
                                              }}
                                            />{' '}
                                            {isCategoryOpens?.find(
                                              (elem: any) =>
                                                elem == category.pos_id
                                            )
                                              ? 'Hide'
                                              : 'Show'}
                                          </button>
                                          <button
                                            onClick={() =>
                                              EditCategoryModal(category)
                                            }
                                            className="show-btn"
                                            style={{
                                              backgroundColor: '#FFFFFF',
                                            }}
                                          >
                                            <img
                                              src={Edit}
                                              alt="edit"
                                              style={{
                                                height: '20px',
                                                width: '20px',
                                                marginRight: '10px',
                                              }}
                                            />
                                            Edit
                                          </button>
                                          {loadingStatusChangeCategory[
                                            category.id
                                          ] ? (
                                            <CircularProgress
                                              size={20}
                                              style={{
                                                height: '20px',
                                                width: '20px',
                                                marginRight: '10px',
                                                marginLeft: '16px',
                                              }}
                                              sx={{ color: '#8B5CFF' }}
                                            />
                                          ) : (
                                            <>
                                              {category.status === 0 ? (
                                                <img
                                                  src={HideEye}
                                                  alt="edit"
                                                  style={{
                                                    height: '20px',
                                                    width: '20px',
                                                    marginRight: '10px',
                                                    marginLeft: '16px',
                                                  }}
                                                  onClick={() => {
                                                    changeStatus(
                                                      'categories',
                                                      category.id,
                                                      null
                                                    );
                                                  }}
                                                />
                                              ) : (
                                                <img
                                                  src={Eye}
                                                  alt="edit"
                                                  style={{
                                                    height: '20px',
                                                    width: '20px',
                                                    marginRight: '10px',
                                                    marginLeft: '16px',
                                                  }}
                                                  onClick={() => {
                                                    changeStatus(
                                                      'categories',
                                                      category.id,
                                                      null
                                                    );
                                                  }}
                                                />
                                              )}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        className="deails-row"
                                        style={{
                                          backgroundColor: '#FFFFFF',
                                          height: '40px',
                                        }}
                                      >
                                        <>
                                          <div className="category-container">
                                            <p
                                              className="category-desc"
                                              style={{ color: '#F34A4A' }}
                                            >
                                              {' '}
                                              Category
                                            </p>
                                            <h3 className="hot-selling-header">
                                              {category.name}
                                            </h3>
                                          </div>
                                          <div className="operate-container">
                                            {!isCategoryOpens.find(
                                              (elem: any) =>
                                                elem == category.pos_id
                                            ) ? (
                                              <button
                                                onClick={(e) => {
                                                  handleCategorySelection(
                                                    category.pos_id
                                                  );
                                                  setGlobalCategoryPosId(
                                                    category.pos_id
                                                  );
                                                }}
                                                className="show-btn"
                                                style={{
                                                  backgroundColor: '#FFFFFF',
                                                }}
                                              >
                                                <img
                                                  src={ArrowDown}
                                                  alt="down"
                                                  style={{
                                                    height: '20px',
                                                    width: '20px',
                                                    marginRight: '10px',
                                                  }}
                                                />
                                                Show
                                              </button>
                                            ) : (
                                              <button
                                                onClick={() =>
                                                  HandleNameCategoryClose(
                                                    category.pos_id
                                                  )
                                                }
                                                className="show-btn"
                                                style={{
                                                  backgroundColor: '#FFFFFF',
                                                }}
                                              >
                                                <img
                                                  src={ArrowUp}
                                                  alt="up"
                                                  style={{
                                                    height: '20px',
                                                    width: '20px',
                                                    marginRight: '10px',
                                                  }}
                                                />
                                                Hide
                                              </button>
                                            )}
                                            <button
                                              onClick={() =>
                                                EditCategoryModal(category)
                                              }
                                              className="show-btn"
                                              style={{
                                                backgroundColor: '#FFFFFF',
                                              }}
                                            >
                                              <img
                                                src={Edit}
                                                alt="edit"
                                                style={{
                                                  height: '20px',
                                                  width: '20px',
                                                  marginRight: '10px',
                                                }}
                                              />
                                              Edit
                                            </button>

                                            {loadingStatusChangeCategory[
                                              category.id
                                            ] ? (
                                              <CircularProgress
                                                size={20}
                                                style={{
                                                  height: '20px',
                                                  width: '20px',
                                                  marginRight: '10px',
                                                  marginLeft: '16px',
                                                }}
                                                sx={{ color: '#8B5CFF' }}
                                              />
                                            ) : (
                                              <>
                                                {category.status === 0 ? (
                                                  <img
                                                    src={HideEye}
                                                    alt="edit"
                                                    style={{
                                                      height: '20px',
                                                      width: '20px',
                                                      marginRight: '10px',
                                                      marginLeft: '16px',
                                                    }}
                                                    onClick={() => {
                                                      changeStatus(
                                                        'categories',
                                                        category.id,
                                                        null
                                                      );
                                                    }}
                                                  />
                                                ) : (
                                                  <img
                                                    src={Eye}
                                                    alt="edit"
                                                    style={{
                                                      height: '20px',
                                                      width: '20px',
                                                      marginRight: '10px',
                                                      marginLeft: '16px',
                                                    }}
                                                    onClick={() => {
                                                      changeStatus(
                                                        'categories',
                                                        category.id,
                                                        null
                                                      );
                                                    }}
                                                  />
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </>
                                      </div>
                                    )}
                                  </AccordionSummary>
                                  {selectedCategory.find(
                                    (elem: any) => elem === category.pos_id
                                  ) && (
                                    <div>
                                      <AccordionDetails>
                                        {categoryLoading ? (
                                          <div
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                            }}
                                          >
                                            <CircularProgress
                                              size={25}
                                              style={{
                                                height: '20px',
                                                width: '20px',
                                                marginRight: '10px',
                                                marginLeft: '16px',
                                              }}
                                              sx={{ color: '#8B5CFF' }}
                                            />
                                          </div>
                                        ) : (
                                          <>
                                            {category ? (
                                              <>
                                                {itemsListArray?.length > 0 &&
                                                  itemsListArray?.map(
                                                    (
                                                      item: any,
                                                      itemIndex: any
                                                    ) => (
                                                      // <Draggable key={item.id} draggableId={item.id} index={itemIndex}>
                                                      // 	{(provided, snapshot) => (
                                                      // 		<div
                                                      // 			ref={provided.innerRef}
                                                      // 			{...provided.draggableProps}
                                                      // 			{...provided.dragHandleProps}
                                                      // 		>

                                                      <ItemComponent
                                                        item={item}
                                                        itemIndex={itemIndex}
                                                        HotSellingChangeStatus={
                                                          HotSellingChangeStatus
                                                        }
                                                        setOrderOptions={
                                                          setOrderOptions
                                                        }
                                                        setSpecialRequests={
                                                          setSpecialRequests
                                                        }
                                                        setCategoryLoading={
                                                          setCategoryLoading
                                                        }
                                                        setLoadingStatusChangeCategory={
                                                          setLoadingStatusChangeCategory
                                                        }
                                                        setLoadingStatusChangeItems={
                                                          setLoadingStatusChangeItems
                                                        }
                                                        setLoadingStatusChange={
                                                          setLoadingStatusChange
                                                        }
                                                        setTagEditModal={
                                                          setTagEditModal
                                                        }
                                                        globalCategoryPosId={
                                                          globalCategoryPosId
                                                        }
                                                        setSchedule={
                                                          setSchedule
                                                        }
                                                        setCrossSellingData={
                                                          setCrossSellingData
                                                        }
                                                        setUpSellingData={
                                                          setUpSellingData
                                                        }
                                                        setEditItemObject={
                                                          setEditItemObject
                                                        }
                                                        setKimStock={
                                                          setKimStock
                                                        }
                                                        setIsLoading={
                                                          setIsLoading
                                                        }
                                                        setEditItem={
                                                          setEditItem
                                                        }
                                                        setEditCrossSelling={
                                                          setEditCrossSelling
                                                        }
                                                        setEditUpSelling={
                                                          setEditUpSelling
                                                        }
                                                        setItemDesc={
                                                          setItemDesc
                                                        }
                                                        setListHide={
                                                          setListHide
                                                        }
                                                        changeStatus={
                                                          changeStatus
                                                        }
                                                      />

                                                      // 		</div>
                                                      // 	)}
                                                      // </Draggable>
                                                    )
                                                  )}
                                              </>
                                            ) : null}
                                          </>
                                        )}
                                      </AccordionDetails>
                                    </div>
                                  )}
                                </Accordion>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      )
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {loading && (
              <div
                style={{
                  position: 'fixed',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <CircularProgress sx={{ color: '#8B5CFF' }} />
              </div>
            )}
            <Allergen />
          </div>
        </>
      )}

      <ModifierModal
        editModifier={editmodifierSet}
        closeModal={() => setEditmodifierSet(false)}
        modifier={selectedModifierItem}
        setModifier={setSelectedModifierItem}
      />

      <EditVariationModal
        editVariation={editVariation}
        setEditVariation={setEditVariation}
        editAllergen={editAllergen}
      />

      <Modal
        open={editItem}
        onClose={() => {
          setItemNameEdit('');
          setEditItem(false);
          setSchedule('');
          setEditItemObject({});
          setSpecialRequests({
            item_quantities_instructions: '',
            special_request_instructions: '',
          });
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            bottom: editAllergen ? '0' : '-100%',
            transition: 'bottom 0.3s ease-in-out',
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '47.73vw',
            height: '86.74vh',
            bgcolor: 'background.paper',
            borderRadius: '8px',
            boxShadow: 24,
            padding: '16px',
            borderWidth: 0,
            overflowY: 'auto',
          }}
        >
          <style>{`:: -webkit - scrollbar { width: 6px; } :: -webkit - scrollbar - track { display: none; } :: -webkit - scrollbar - thumb { background - color: transparent; } `}</style>
          <div className="import-title-container">
            <h3 className="import-title">Edit Item</h3>
            <div className="category-items-btn" style={{ display: 'flex' }}>
              <img
                onClick={() => setEditItem(false)}
                src={Cross}
                alt="Cross"
                style={{
                  width: '30px',
                  height: '32px',
                  backgroundColor: '#F34A4A29',
                  borderRadius: '33px',
                  cursor: 'pointer',
                }}
              />
            </div>
          </div>
          <div className="menu-details">
            <h3 className="menu-title-header">General Details</h3>
            <h3 className="menu-heading">Item Name</h3>
            <input
              placeholder="Menu Name"
              value={editItemObject?.name}
              onChange={(e: any) =>
                setEditItemObject({ ...editItemObject, name: e.target.value })
              }
              style={{
                marginTop: '8px',
                width: '44.94vw',
                height: '7.20vh',
                borderRadius: '16px',
                borderWidth: '1px',
                borderColor: '#201C1C3D',
                padding: '10px 16px 10px 16px',
                outline: 'none',
                fontWeight: '400',
                fontSize: '14px',
                fontFamily: 'Poppins',
              }}
            />
            <p className="input-sub-text">
              This text is the name of the item that will show up in your
              miniosks...
            </p>
            <h3 className="menu-title-header" style={{ paddingTop: '16px' }}>
              Allergen
            </h3>
            <div
              className="checkbox-container-edit-item"
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <div
                className="checkbox-container-edit-item"
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                {editItemObject?.all_allergens?.map((allergen: any) => (
                  <FormControlLabel
                    key={allergen.id}
                    control={
                      <Checkbox
                        checked={allergen.status} // Check if the status is true
                        onChange={() => handleCheckboxChange(allergen.id)}
                      />
                    }
                    label={allergen.name}
                    color="#201C1C"
                    className="check-box"
                    style={{
                      fontWeight: '500',
                      fontSize: '12px',
                      fontFamily: 'Poppins',
                      height: '20px',
                      marginTop: '16px',
                    }}
                  />
                ))}
              </div>
            </div>
            <h3 className="menu-title-header" style={{ paddingTop: '16px' }}>
              Order Type
            </h3>
            <div
              className="checkbox-container-edit-item"
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={orderOptions.dine_in}
                    onChange={() => handleOrderOptionChange('dine_in')}
                  />
                }
                label="Dine In Only"
                color="#201C1C"
                className="check-box"
                style={{
                  fontWeight: '500',
                  fontSize: '12px',
                  fontFamily: 'Poppins',
                  height: '20px',
                  marginTop: '16px',
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={orderOptions.take_out}
                    onChange={() => handleOrderOptionChange('take_out')}
                  />
                }
                label="Take Out Only"
                color="#201C1C"
                className="check-box"
                style={{
                  fontWeight: '500',
                  fontSize: '12px',
                  fontFamily: 'Poppins',
                  height: '20px',
                  marginTop: '16px',
                }}
              />
              {/* <FormControlLabel control={<Checkbox checked={orderOptions.both} onChange={() => handleOrderOptionChange("both")} />} label="Both" color="#201C1C" className="check-box" style={{ fontWeight: "500", fontSize: "12px", fontFamily: "Poppins", height: "20px", marginTop: "16px", }} /> */}
            </div>
            <h3 className="menu-title-header" style={{ paddingTop: '16px' }}>
              Current Availability at Restaurant KIM
            </h3>
            <h3 className="menu-heading" style={{ paddingTop: '16px' }}>
              Restaurant KIM Stock
            </h3>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
              paddingTop: '8px',
            }}
          >
            <input
              readOnly={true}
              placeholder="Restaurant KIM Stock"
              value={kimStock}
              onChange={(e: any) => setKimStock(e.target.value)}
              style={{
                width: '44.94vw',
                height: '6vh',
                borderRadius: '8px',
                borderWidth: '1px',
                borderColor: '#201C1C3D',
                padding: '10px 16px 10px 16px',
                outline: 'none',
                fontWeight: '400',
                fontSize: '14px',
                fontFamily: 'Poppins',
              }}
            />
            <div style={{ position: 'absolute', right: '10px' }}>
              <h3
                style={{
                  margin: 0,
                  fontWeight: '500',
                  fontSize: '11px',
                  color: '#201C1C3D',
                  fontFamily: 'Poppins',
                }}
              >
                Last updated at 01/24/2022 03:57 AM
              </h3>
            </div>
          </div>
          <p className="title-sub-text">
            You can modify this value to manually override the stock directly in
            Miniosk, but it won't change your inventory in Square. Manually
            overriding stock is available on the off chance that something goes
            wrong on Square's end for inventory.
          </p>
          <h3 className="menu-title-header">Photo</h3>
          <p className="title-sub-text">
            This uploaded image will show up in the miniosk for this category,
            depending on any Styling options you select.
          </p>
          <div>
            <div
              className="photo-container"
              style={{
                marginTop: '14px',
                width: '152.44px',
                height: '152.44px',
              }}
            >
              <img
                src={editItemObject?.item_image?.url}
                alt="Uploaded"
                style={{ height: '150px', width: '152.44px' }}
              />
            </div>
          </div>

          <input
            value={
              editItemObject?.description === 'null'
                ? ''
                : editItemObject?.description
            }
            onChange={(e) =>
              setEditItemObject({
                ...editItemObject,
                description: e.target.value,
              })
            }
            placeholder="Type Description here"
            style={{
              paddingLeft: '16px',
              width: '44.94vw',
              height: '6vh',
              borderRadius: '8px',
              borderWidth: '1px',
              borderColor: '#201C1C3D',
              outline: 'none',
              fontWeight: '400',
              fontSize: '14px',
              fontFamily: 'Poppins',
              marginTop: '16px',
            }}
          />
          <p className="title-sub-text">
            This text is a description of the item that will show up in your
            miniosks, if Styling settings are set up to show descriptions.
          </p>

          <div style={{ flexDirection: 'row' }}>
            <button
              onClick={() => EditItemName(editItemObject?.item_id)}
              className="common-button-blue"
            >
              {loading ? (
                <CircularProgress color="inherit" size={25} />
              ) : (
                'Apply'
              )}
            </button>
            <button
              onClick={() => setEditItem(false)}
              className="common-button-cancel"
            >
              Cancel
            </button>
          </div>
        </Box>
      </Modal>

      <CategoryModal
        editCategory={editCategory}
        closeModal={() => setEditCategory(false)}
        category={editCategoryObject}
        setCategory={setEditCategoryObject}
      />

      <Modal
        open={tagEditModal}
        onClose={() => {
          setTagEditModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            transition: 'bottom 0.3s ease-in-out',
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            borderRadius: '8px',
            boxShadow: 24,
            padding: '16px',
            borderWidth: 0,
            overflowY: 'auto',
          }}
        >
          <style>{`::-webkit-scrollbar { width: 6px; } ::-webkit-scrollbar-track { display: none; } ::-webkit-scrollbar-thumb { background-color: transparent; } `}</style>
          <div>
            <h1 className="import-title">Edit Tag Name</h1>
            <input
              value={editTagname}
              onChange={handleTagNameChange}
              placeholder="Tag Name"
              className="miniosk-input"
              style={{
                marginTop: '8px',
                width: '360px',
                height: '44px',
                borderRadius: '8px',
                borderWidth: '1px',
                borderColor: '#201C1C3D',
                padding: '10px 16px 10px 16px',
                outline: 'none',
                fontWeight: '400',
                fontSize: '14px',
                fontFamily: 'Poppins',
                color: '#201C1C',
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '16px',
              }}
            >
              <button className="tag-save-button" onClick={() => TagNameEdit()}>
                Save{' '}
              </button>
              <button
                className="tag-cancel-button"
                onClick={() => {
                  setTagEditModal(false);
                }}
              >
                Cancel{' '}
              </button>
            </div>
          </div>
        </Box>
      </Modal>

      <SuccessModal
        open={editHotSellingModal}
        onClose={() => {
          seteditHotSellingModal(false);
          window.location.reload();
        }}
        headerText="Successful!"
        bodyText="Your Hot Selling Items Updated successfully."
      />

      <SuccessModal
        open={editUpSellingModal}
        onClose={() => {
          seteditUpSellingModal(false);
        }}
        headerText="Successful!"
        bodyText="Your Up Selling Items Updated successfully."
      />

      <SuccessModal
        open={editCrossSellingModal}
        onClose={() => {
          seteditCrossSellingModal(false);
        }}
        headerText="Successful!"
        bodyText="Your Cross Selling Items Updated successfully."
      />

      <EditSellingSearchModel
        open={editHotSelling}
        onClose={() => setEditHotSelling(false)}
        addDrp={AddHotDrpData}
        handleChange={handleChangeHotSelling}
        heading="Hot Selling"
        selectedItems={selectedHotSellingItems}
        sellingDrp={HotSellingDrp}
        HotSellingList={HotSellingList}
        setSelectedItems={setSelectedHotSellingItems}
      />

      {/* <EditSellingModel open={editHotSelling} onClose={() => setEditHotSelling(false)} addDrp={AddHotDrpData} handleChange={handleChangeHotSelling} heading="Hot Selling" selectedItems={selectedHotSellingItems} sellingDrp={HotSellingDrp} HotSellingList={HotSellingList} setSelectedItems={setSelectedHotSellingItems} /> */}

      <EditSellingModel
        open={editUpSelling}
        onClose={() => setEditUpSelling(false)}
        addDrp={(item_id: any) => AddUpDrpData(item_id)}
        handleChange={handleChangeUpselling}
        heading="Up Selling"
        selectedItems={selectedUpSellingItems}
        sellingDrp={HotSellingDrp}
        setSelectedItems={setSelectedUpSellingItems}
      />

      <EditSellingModel
        open={editCrossSelling}
        onClose={() => setEditCrossSelling(false)}
        addDrp={(item_id: any) => AddCrossDrpData(item_id)}
        handleChange={handleChangeCrossSelling}
        heading="Cross Selling"
        selectedItems={selectedCrossSellingItems}
        ListItemId={globalItemId}
        sellingDrp={HotSellingDrp}
        setSelectedItems={setSelectedCrossSellingItems}
      />
    </div>
  );
}

export default MenuDetails;
