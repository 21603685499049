import React from "react";
import { CircularProgress, List } from "@mui/material";
import ArrowDown from "../../../assets/images/ArrowDown.png";
import ArrowUp from "../../../assets/images/ArrowUp.png";
import Eye from "../../../assets/images/eye.png";
import HideEye from "../../../assets/images/hide-eye.png";
import Dots from "../../../assets/images/dots.png";

type ItemVariationComponentProps = {
  variationHide: boolean;
  HandleVariation: any;
  VariationClose: any;
  setGlobalVariationId: any;
  item: any;
  loadingvariationStatus: any;
  globalItemId: any;
  variation_status: any;
  changeStatus: any;
  itemVariationsList: any;
  loadingItemvariationStatus: any;
};

const ItemVariationComponent = ({
  variationHide,
  HandleVariation,
  VariationClose,
  setGlobalVariationId,
  item,
  loadingvariationStatus,
  globalItemId,
  variation_status,
  changeStatus,
  itemVariationsList,
  loadingItemvariationStatus
}: ItemVariationComponentProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        paddingTop: "16px",
        marginLeft: "16px",
      }}
    >
      <div className={`deails - row`}>
        <div className="item-list-container">
          <div className="item-list-sub-container">
            <p className="list-title">Item Variation</p>
          </div>
          <div className="operate-container">
            {variationHide ? (
              <button
                onClick={HandleVariation}
                className="show-btn"
                style={{ backgroundColor: "#FBFBFB" }}
              >
                <img
                  src={ArrowUp}
                  alt="up"
                  style={{ height: "20px", width: "20px", marginRight: "10px" }}
                />
                Hide
              </button>
            ) : (
              <button
                onClick={() => {
                  VariationClose();
                  setGlobalVariationId(item.id);
                }}
                className="show-btn"
                style={{ backgroundColor: "#FBFBFB" }}
              >
                <img
                  src={ArrowDown}
                  alt="down"
                  style={{ height: "20px", width: "20px", marginRight: "10px" }}
                />
                Show
              </button>
            )}

            {loadingvariationStatus[globalItemId] ? (
              <CircularProgress
                size={20}
                style={{
                  height: "20px",
                  width: "20px",
                  marginRight: "10px",
                  marginLeft: "16px",
                }}
                sx={{ color: "#8B5CFF" }}
              />
            ) : (
              <>
                {variation_status === 0 ? (
                  <img
                    src={HideEye}
                    alt="edit"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "10px",
                      marginLeft: "16px",
                    }}
                    onClick={() => {
                      changeStatus("variation", globalItemId, null);
                    }}
                  />
                ) : (
                  <img
                    src={Eye}
                    alt="edit"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "10px",
                      marginLeft: "16px",
                    }}
                    onClick={() => {
                      changeStatus("variation", globalItemId, null);
                    }}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {variationHide && (
        <>
          {itemVariationsList && itemVariationsList?.length > 0 ? (
            itemVariationsList?.map((item: any, index: any) => (
              <List sx={{ mt: 0, p: 0 }} key={item.id}>
                <div className={`deails - row`} style={{}}>
                  <div
                    className="item-list-details"
                    style={{ paddingTop: "8px", paddingBottom: "8px" }}
                  >
                    <div className="hot-selling">
                      <img
                        alt="dots"
                        src={Dots}
                        style={{
                          height: "24px",
                          width: "24px",
                          marginLeft: "16px",
                        }}
                      />
                      <h3 className="hot-selling-header">{item.name}</h3>
                      {item.sold_out === 1 ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: "10px",
                            backgroundColor: "#F34A4A29",
                            borderRadius: "8px",
                          }}
                        >
                          <h1
                            style={{
                              fontSize: "10px",
                              margin: 0,
                              padding: "5px",
                              fontFamily: "Poppins",
                              fontWeight: "bold",
                              color: "#F34A4A",
                            }}
                          >
                            {" "}
                            Sold Out{" "}
                          </h1>
                        </div>
                      ) : null}
                    </div>
                    <div className="operate-container">
                      <div className="operate-container">
                        <h3 className="price">
                          {" "}
                          $ {item?.price_money.amount / 100}{" "}
                        </h3>
                      </div>
                      {loadingItemvariationStatus[item.id] ? (
                        <CircularProgress
                          size={20}
                          style={{
                            height: "20px",
                            width: "20px",
                            marginRight: "10px",
                            marginLeft: "16px",
                          }}
                          sx={{ color: "#8B5CFF" }}
                        />
                      ) : (
                        <>
                          {item.status === 0 ? (
                            <img
                              src={HideEye}
                              alt="edit"
                              style={{
                                height: "20px",
                                width: "20px",
                                marginRight: "10px",
                                marginLeft: "16px",
                              }}
                              onClick={() => {
                                changeStatus("item_variations", item.id, null);
                              }}
                            />
                          ) : (
                            <img
                              src={Eye}
                              alt="edit"
                              style={{
                                height: "20px",
                                width: "20px",
                                marginRight: "10px",
                                marginLeft: "16px",
                              }}
                              onClick={() => {
                                changeStatus("item_variations", item.id, null);
                              }}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </List>
            ))
          ) : (
            <List sx={{ mt: 0, p: 0 }} key={item.id}>
              <div className={`deails - row`}>
                <div
                  className="item-list-details"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: "8px",
                  }}
                >
                  <div className="hot-selling">
                    <h3 className="hot-selling-header">No Variations Found</h3>
                  </div>
                </div>
              </div>
            </List>
          )}
        </>
      )}
    </div>
  );
};

export default ItemVariationComponent;
