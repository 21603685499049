import React, { Fragment, useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  useTheme,
  TableCell,
  TableContainer,
  TableHead,
  Pagination,
  TableRow,
  TableSortLabel,
  Typography,
  CircularProgress,
} from '@mui/material';
import { useStyles } from './../../components/UseStyles';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { Menu, MenuItem } from '@mui/material';
import { IconButton } from '@mui/material';
import BackNavigation from '../../components/BackNavigation';
import { visuallyHidden } from '@mui/utils';
import ApiServices from '../../helpers/ApiServices';
import HelperModule from '../../../src/helpers/HelperModule';
import { useDispatch, useSelector } from 'react-redux';
import { setVendorBillingInfo } from '../../store/reducers/VendorDetailsReducer';
import Search from '../../assets/images/Search.png';
import { ClearIcon } from '@mui/x-date-pickers/icons';
import Download from '../../../src/assets/images/Download.png';
import BillingChart from './billingChart';
import DataTable from '../../components/DataTable';

let UserCodeData: any;
function createData(
  transaction_id: any,
  date: any,
  subscription_type: any,
  payment_type: any,
  amount: any
) {
  return { transaction_id, date, subscription_type, payment_type, amount };
}
interface Transaction {
  transactionId: string;
  date: string;
  subscriptionType: string;
  paymentType: string;
  amount: string;
}

interface HeadCell {
  id: string;
  label: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);

  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    // console.log(order);

    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis?.map((el) => el[0]);
}

const headCells: any = [
  {
    id: 'reference_id',
    disablePadding: true,
    label: 'Reference Id',
  },
  {
    id: 'created_at_date',
    disablePadding: false,
    label: 'Date',
  },
  {
    id: 'net_amount',
    disablePadding: false,
    label: 'Amount',
  },
  {
    id: 'state',
    disablePadding: false,
    label: 'State',
  },
  // {
  //   id: "amount",
  //   disablePadding: false,
  //   label: "Amount",
  // },
];

function EnhancedTableHead(props: any) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort, controller } = props;

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells?.map((headCell: any) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              className={classes.TableHeadTitle}
              active={controller.field === headCell.id} // orderBy === headCell.id
              direction={controller.order === 'asc' ? 'asc' : 'desc'}
              onClick={createSortHandler(headCell.id)}
              IconComponent={(event) => (
                <ArrowDropDownRoundedIcon
                  {...event}
                  sx={{ color: '#201C1C52' }}
                />
              )}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function BillingInfoTable() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { vendorBillingInfo } = useSelector(
    (state: any) => state.vendorDetails
  );
  const { palette } = useTheme();
  const [orderId, setOrderId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;
  const [selectedRow, setSelectedRow]: any = useState(null);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = vendorBillingInfo?.data
  const totalRecords = vendorBillingInfo?.total;
  const [recordsCount, setRecordsCount] = useState(0);
  const [controller, setController] = useState({
    page: 1,
    rowsPerPage: 10,
    field: '',
    order: '',
  });
  const nPages = Math.ceil(recordsCount / controller.rowsPerPage);
  const [loading, setLoading] = React.useState(true);
  const [order, setOrder] = React.useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = React.useState<string>('date');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const UserId = localStorage.getItem('UserID');
  const userCode = localStorage.getItem('UserCode');
  const [search, setSearch] = useState('');
  const [filteredValues, setFilteredValues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, []);

  // useEffect(() => {
  //   setController({
  //     ...controller,
  //     page: 1,
  //   });
  // }, [filteredValues]);

  const BillingInfo = async () => {
    setIsLoading(true);
    try {
      // const response = await HelperModule().getMethod(
      //   ApiServices.base_URL +
      //     ApiServices.billing_info +
      //     UserId +
      //     `&page=${page}`
      // );
      const response = await HelperModule().getMethod(
        ApiServices.base_URL +
          ApiServices.order_list +
          `?user_code=${userCode}&duration=current_month&page=${controller.page}`
      );
      if (response.status === true) {
        setRecordsCount(response?.data?.total);
        dispatch(setVendorBillingInfo(response.data));
        setFilteredValues(response?.data?.data);
        setIsLoading(false);
      } else {
        console.log('Error Response', response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadBillingInvoice = async (row: any) => {
    window.open(
      `https://api.miniosk.com/api/order/download-order-receipt/${row?.id}`
    );
    // try {
    //   const response = await fetch(
    //     `${ApiServices.base_URL}${ApiServices.download_billing_info}${orderId}`
    //   );
    //   console.log(response)
    //   // if (!response.ok) {
    //   //   throw new Error(
    //   //     `Failed to download the invoice. Response status: ${response.status}`
    //   //   );
    //   // }
    //   // const blob = await response.blob();
    //   // const url = URL.createObjectURL(blob);
    //   // const link = document.createElement("a");
    //   // link.href = url;
    //   // link.setAttribute("download", `Invoice_${orderId}.pdf`);
    //   // document.body.appendChild(link);
    //   // link.click();
    //   // setTimeout(() => {
    //   //   document.body.removeChild(link);
    //   // }, 100);
    // } catch (error) {
    //   console.error("Error occurred while downloading the invoice:", error);
    // }
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }
    BillingInfo();
    console.log('Billing Info', controller);
  }, [controller.page]);

  const handleSearch = (value: string) => {
    setSearch(value);
    const lowerCaseSearch = value.toLowerCase();

    // Filter the data based on the search value
    const filteredList = vendorBillingInfo?.data?.filter(
      (billing: any) =>
        (billing.transaction_id &&
          billing.transaction_id.toLowerCase().includes(lowerCaseSearch)) ||
        (billing.subscription_type &&
          billing.subscription_type.toLowerCase().includes(lowerCaseSearch)) ||
        (billing.payment_type && billing.payment_type.includes(value)) ||
        (billing.date && billing.date.toString().includes(value)) ||
        (billing.amount && billing.amount.toString().includes(value))
    );

    setFilteredValues(filteredList);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    setController({
      ...controller,
      field: property,
      order:
        property !== controller.field
          ? 'desc'
          : controller.order === 'asc'
          ? 'desc'
          : 'asc',
      page: 1,
    });

    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuItems: HeadCell[] = [
    { id: 'transactionId', label: 'Transaction ID' },
    { id: 'date', label: 'Date' },
    { id: 'subscriptionType', label: 'Subscription Type' },
    { id: 'paymentType', label: 'Payment Type' },
    { id: 'amount', label: 'Amount' },
  ];

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    // console.log("value", value);
    setCurrentPage(value);
    setController({
      ...controller,
      page: value,
    });
  };

  // const visibleRows = React.useMemo(() => stableSort(vendorBillingInfo?.length > 0 ? vendorBillingInfo : currentRecords, getComparator(order, orderBy)), [order, orderBy, currentRecords, vendorBillingInfo]);
  // const paginatedRows = visibleRows?.slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage);

  const visibleRows = React.useMemo(() => {
    const dataToDisplay =
      filteredValues.length > 0 ? filteredValues : currentRecords;
    const sortedData = stableSort(dataToDisplay, getComparator(order, orderBy));
    // return sortedData.slice(indexOfFirstRecord, indexOfLastRecord); // Limiting to 10 records
    return currentRecords;
  }, [order, orderBy, currentRecords, filteredValues]);

  const actionButton = (
    <button
      onClick={(e) => e.stopPropagation()}
      style={{
        width: '122px',
        marginLeft: '84px',
        height: '34px',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#8B5CFF',
        color: '#FFFFFF',
        borderWidth: 0,
        fontWeight: '500',
        fontSize: '12px',
        fontFamily: 'Poppins',
        borderRadius: '8px',
        transition: 'background-color 0.3s',
      }}
      onMouseEnter={(e) => {
        (e.target as HTMLButtonElement).style.backgroundColor = '#6d43e57D';
      }}
      onMouseLeave={(e) => {
        (e.target as HTMLButtonElement).style.backgroundColor = '#8B5CFF';
      }}
    >
      <img
        src={Download}
        alt="Download"
        style={{
          height: '12px',
          width: '8px',
          marginRight: '10px',
        }}
      />
      Invoice
    </button>
  );

  return (
    <div>
      <BackNavigation label="Settings" />
      {loading ? (
        <div className="Loader-container">
          <CircularProgress disableShrink sx={{ color: '#8B5CFF' }} />
        </div>
      ) : (
        <Box className={classes.TableMain}>
          <Typography
            variant="h4"
            // gutterBottom
            // style={{ marginBottom: "-32px" }}
          >
            Billing info
          </Typography>
          <div>
            <BillingChart />
          </div>
          <div
            className="manage-tabs-container"
            // style={{ marginBottom: "-48px" }}
          >
            <div
              className="manage-btn-container"
              style={{ position: 'relative', display: 'inline-block' }}
            >
              <img src={Search} alt="Search Icon" className="search-icon" />
              <input
                placeholder="Search..."
                className="promo-code-input-container"
                style={{ color: 'black', outline: 'none' }}
                value={search}
                onChange={(event) => handleSearch(event.target.value)}
              />
              {search && (
                <IconButton
                  style={{
                    position: 'absolute',
                    right: '8px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                  onClick={() => setSearch('')}
                >
                  <ClearIcon
                    onClick={() => setFilteredValues(vendorBillingInfo?.data)}
                  />
                </IconButton>
              )}
            </div>
          </div>
          <Fragment>
            <DataTable
              order={order}
              orderBy={orderBy}
              nPages={nPages}
              currentRecords={currentRecords}
              visibleRows={visibleRows}
              controller={controller}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
              setController={setController}
              setCurrentPage={setCurrentPage}
              headCells={headCells}
              loading={isLoading}
              actionElement={actionButton}
              onActionClick={downloadBillingInvoice}
            />
          </Fragment>
        </Box>
      )}
    </div>
  );
}
export default BillingInfoTable;
