import { List } from "@mui/material";
import React from "react";
import ArrowDown from "../../../assets/images/ArrowDown.png";
import ArrowUp from "../../../assets/images/ArrowUp.png";
import Dots from "../../../assets/images/dots.png";
import Edit from "../../../assets/images/edit.png";

type TagProps = {
  tagView: boolean;
  TagClose: any;
  items_sub_list: any;
  setTagEditModal: any;
  HandleTag: any;
};

const Tag = ({
  tagView,
  TagClose,
  items_sub_list,
  setTagEditModal,
  HandleTag
}: TagProps) => {
    console.log("items_sub_list", items_sub_list);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        paddingTop: "8px",
        marginLeft: "16px",
      }}
    >
      <div className={`deails - row`}>
        <div className="item-list-container">
          <div className="item-list-sub-container">
            <p className="list-title">Tag</p>
          </div>
          <div className="operate-container">
            {tagView ? (
              <button
                onClick={HandleTag}
                className="show-btn"
                style={{ backgroundColor: "#FBFBFB" }}
              >
                <img
                  src={ArrowUp}
                  alt="up"
                  style={{ height: "20px", width: "20px", marginRight: "10px" }}
                />
                Hide
              </button>
            ) : (
              <button
                onClick={TagClose}
                className="show-btn"
                style={{ backgroundColor: "#FBFBFB" }}
              >
                <img
                  src={ArrowDown}
                  alt="down"
                  style={{ height: "20px", width: "20px", marginRight: "10px" }}
                />
                Show
              </button>
            )}
          </div>
        </div>
      </div>
      {tagView && (
        <>
          {items_sub_list?.tags?.length > 0 ? (
            <List sx={{ mt: 0, p: 0 }}>
              <div className={`deails - row`} style={{}}>
                <div
                  className="item-list-details"
                  style={{ paddingBottom: "8px" }}
                >
                  <div className="hot-selling">
                    <img
                      alt="dots"
                      src={Dots}
                      style={{
                        height: "24px",
                        width: "24px",
                        marginLeft: "16px",
                      }}
                    />
                    <h3 className="hot-selling-header">
                      {items_sub_list?.tags[0].name || "Tag Name"}
                    </h3>
                  </div>
                  <div className="operate-container">
                    <div className="operate-container">
                      {/* <h3 className='price'>$ {item?.price_money.amount / 100}</h3> */}
                      <button
                        onClick={() => setTagEditModal(true)}
                        className="show-btn"
                        style={{ backgroundColor: "#FBFBFB" }}
                      >
                        <img
                          src={Edit}
                          alt="edit"
                          style={{
                            height: "20px",
                            width: "20px",
                            marginRight: "10px",
                          }}
                        />
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </List>
          ) : (
            <List sx={{ mt: 0, p: 0 }}>
              <div className={`deails - row`}>
                <div
                  className="item-list-details"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: "8px",
                  }}
                >
                  <div className="hot-selling">
                    <h3 className="hot-selling-header">No Tags Found</h3>
                  </div>
                </div>
              </div>
            </List>
          )}
        </>
      )}
    </div>
  );
};

export default Tag;
