import { CircularProgress, List } from "@mui/material";
import React from "react";
import ArrowDown from "../../../assets/images/ArrowDown.png";
import ArrowUp from "../../../assets/images/ArrowUp.png";
import Eye from "../../../assets/images/eye.png";
import HideEye from "../../../assets/images/hide-eye.png";
import FoodBowl from "../../../assets/images/foodBowl.png";

type ModifierComponentProps = {
  item: any;
  modifierHide: any;
  ModifierHandler: any;
  HandleModifierClose: any;
  loadingmodifierStatus: any;
  globalItemId: any;
  modifierStatus: any;
  changeStatus: any;
  items_sub_list: any;
};

const ModifierComponent = ({
  item,
  modifierHide,
  ModifierHandler,
  HandleModifierClose,
  loadingmodifierStatus,
  globalItemId,
  modifierStatus,
  changeStatus,
  items_sub_list,
}: ModifierComponentProps) => {
  return (
    <div>
      <div
        className={`deails - row`}
        style={{ marginLeft: "16px", marginTop: "4px" }}
      >
        <div className="item-list-container">
          <div className="item-list-sub-container">
            <p className="list-title"> Modifier Set </p>
          </div>
          <div className="operate-container">
            {!modifierHide ? (
              <button
                onClick={ModifierHandler}
                className="show-btn"
                style={{ backgroundColor: "#FBFBFB" }}
              >
                <img
                  src={ArrowDown}
                  alt="down"
                  style={{ height: "20px", width: "20px", marginRight: "10px" }}
                />
                Show
              </button>
            ) : (
              <button
                onClick={HandleModifierClose}
                className="show-btn"
                style={{ backgroundColor: "#FBFBFB" }}
              >
                <img
                  src={ArrowUp}
                  alt="up"
                  style={{ height: "20px", width: "20px", marginRight: "10px" }}
                />
                Hide
              </button>
            )}
            {loadingmodifierStatus[globalItemId] ? (
              <CircularProgress
                size={20}
                style={{
                  height: "20px",
                  width: "20px",
                  marginRight: "10px",
                  marginLeft: "16px",
                }}
                sx={{ color: "#8B5CFF" }}
              />
            ) : (
              <>
                {modifierStatus === 0 ? (
                  <img
                    src={HideEye}
                    alt="edit"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "10px",
                      marginLeft: "16px",
                    }}
                    onClick={() => {
                      changeStatus("item_modifier", globalItemId, null);
                    }}
                  />
                ) : (
                  <img
                    src={Eye}
                    alt="edit"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "10px",
                      marginLeft: "16px",
                    }}
                    onClick={() => {
                      changeStatus("item_modifier", globalItemId, null);
                    }}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {modifierHide && (
        <>
          {items_sub_list && items_sub_list?.modifiers?.length > 0 ? (
            items_sub_list?.modifiers?.map((modifierItem: any, index: any) => (
              <List key={index} sx={{ mt: 0, p: 0 }}>
                <div className={`deails - row`} style={{ paddingLeft: "16px" }}>
                  <div
                    className="item-list-details"
                    style={{ paddingBottom: "16px" }}
                  >
                    <div className="item-list-sub-container">
                      <div className="hot-selling">
                        <img
                          alt="bowl"
                          src={FoodBowl}
                          style={{
                            height: "24px",
                            width: "24px",
                            marginLeft: "16px",
                          }}
                        />
                        <h3 className="hot-selling-header">
                          {modifierItem?.name}{" "}
                        </h3>
                      </div>
                    </div>
                    <div className="operate-container">
                      <div className="operate-container">
                        <h3 className="price">
                          {" "}
                          $ {modifierItem?.price_money.amount / 100}{" "}
                        </h3>
                      </div>
                      {/* <img src={Eye} alt="edit" style={{ height: "20px", width: "20px", marginRight: "10px", marginLeft: "16px", }} /> */}
                    </div>
                  </div>
                </div>
              </List>
            ))
          ) : (
            <List sx={{ mt: 0, p: 0 }} key={item.id}>
              <div className={`deails - row`} style={{ marginLeft: "16px" }}>
                <div
                  className="item-list-details"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: "8px",
                  }}
                >
                  <div className="hot-selling">
                    <h3 className="hot-selling-header">No Modifiers found</h3>
                  </div>
                </div>
              </div>
            </List>
          )}
        </>
      )}
    </div>
  );
};

export default ModifierComponent;
