import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Cross from '../assets/images/Cross.png';
import { Typography } from '@mui/material';
import { useStyles } from '../components/UseStyles';
import Select from 'react-select'; // Import react-select
import { useSelector } from 'react-redux';

function EditSellingSearchModel({ open, onClose, addDrp, handleChange, selectedItems, sellingDrp, heading, ListItemId }: any) {
    const classes = useStyles();
    const HotSellingList = useSelector((state: any) => state.hotSellingListDetails.HotSellingList);
    const options = sellingDrp.map((item: any) => ({
        id: item.id,
        name: item.name,
        description: item.description,
    }));

    // Function to find if item is selected
    const isItemSelected = (item: any) => {
        return selectedItems.some((selectedItem: any) => selectedItem.id === item.id);
    };

    const filteredSelectedItems = HotSellingList?.filter((item: any) => item.recommended !== 1);


    return (
        <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '35.72vw',
                transition: 'height 0.3s ease-in-out',
                bgcolor: 'background.paper', borderRadius: '8px', boxShadow: 24, padding: '16px', borderWidth: 0
            }}>
                <div className='import-title-container'>
                    <h3 className='import-title'>Select {heading} Items</h3>
                    <img onClick={onClose} src={Cross} alt='Cross' style={{ width: '24px', height: '24px', cursor: "pointer" }} />
                </div>
                <div className="menu-details">
                    <h3 className='menu-heading'>Select Items</h3>
                    <div className="dropdown">
                        <Select
                            className={classes.root}
                            options={options}
                            value={selectedItems}
                            onChange={handleChange}
                            isMulti
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            formatOptionLabel={(option) => (
                                <div>
                                    <Typography sx={{ color: '#8B5CFF', fontWeight: '500', fontSize: '10x', fontFamily: 'Poppins' }}>
                                        {option.name}
                                    </Typography>
                                    <Typography sx={{ color: '#8B5CFF', fontWeight: '500', fontSize: '14px', fontFamily: 'Poppins' }}>
                                        {option.label}
                                    </Typography>
                                </div>
                            )}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '12px', }}>
                        <button onClick={addDrp} className="common-button-blue">Add</button>
                        <button onClick={onClose} className="common-button-cancel">Cancel</button>
                    </div>
                </div>
            </Box>
        </Modal>
    );
}

export default EditSellingSearchModel;
