import React from "react";
import { CircularProgress, List } from "@mui/material";
import ArrowDown from "../../../assets/images/ArrowDown.png";
import ArrowUp from "../../../assets/images/ArrowUp.png";
import Eye from "../../../assets/images/eye.png";
import HideEye from "../../../assets/images/hide-eye.png";
import Dots from "../../../assets/images/dots.png";

type AllergenComponentProps = {
  allergenView: any;
  HandleAllergen: any;
  loadingallergenStatus: any;
  globalItemId: any;
  allergenStatus: any;
  changeStatus: any;
  items_sub_list: any;
  loadingitemalleregenStatus: any;
  itemallergenStatus: any;
  AllergenClose: any;
};

const AllergenComponent = ({
  allergenView,
  HandleAllergen,
  loadingallergenStatus,
  globalItemId,
  allergenStatus,
  changeStatus,
  items_sub_list,
  loadingitemalleregenStatus,
  itemallergenStatus,
  AllergenClose
}: AllergenComponentProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        paddingTop: "8px",
        marginLeft: "16px",
      }}
    >
      <div className={`deails - row`}>
        <div className="item-list-container">
          <div className="item-list-sub-container">
            <p className="list-title">Allergen</p>
          </div>
          <div className="operate-container">
            {allergenView ? (
              <button
                onClick={HandleAllergen}
                className="show-btn"
                style={{ backgroundColor: "#FBFBFB" }}
              >
                <img
                  src={ArrowUp}
                  alt="up"
                  style={{ height: "20px", width: "20px", marginRight: "10px" }}
                />
                Hide
              </button>
            ) : (
              <button
                onClick={AllergenClose}
                className="show-btn"
                style={{ backgroundColor: "#FBFBFB" }}
              >
                <img
                  src={ArrowDown}
                  alt="down"
                  style={{ height: "20px", width: "20px", marginRight: "10px" }}
                />
                Show
              </button>
            )}
            {loadingallergenStatus[globalItemId] ? (
              <CircularProgress
                size={20}
                style={{
                  height: "20px",
                  width: "20px",
                  marginRight: "10px",
                  marginLeft: "16px",
                }}
                sx={{ color: "#8B5CFF" }}
              />
            ) : (
              <>
                {allergenStatus === 0 ? (
                  <img
                    src={HideEye}
                    alt="edit"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "10px",
                      marginLeft: "16px",
                    }}
                    onClick={() => {
                      changeStatus("allergen", globalItemId, null);
                    }}
                  />
                ) : (
                  <img
                    src={Eye}
                    alt="edit"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "10px",
                      marginLeft: "16px",
                    }}
                    onClick={() => {
                      changeStatus("allergen", globalItemId, null);
                    }}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {allergenView && (
        <>
          {items_sub_list?.item_allergens?.length > 0 ? (
            <List sx={{ mt: 0, p: 0 }}>
              <div className={`deails - row`} style={{}}>
                <div
                  className="item-list-details"
                  style={{ paddingBottom: "8px" }}
                >
                  <div className="hot-selling">
                    <img
                      alt="dots"
                      src={Dots}
                      style={{
                        height: "24px",
                        width: "24px",
                        marginLeft: "16px",
                      }}
                    />
                    <h3 className="hot-selling-header">
                      {" "}
                      {items_sub_list?.item_allergens[0].name}
                    </h3>
                  </div>
                  <div className="operate-container">
                    <div className="operate-container">
                      {/* <h3 className='price'>$ {item?.price_money.amount / 100}</h3> */}
                    </div>
                    {loadingitemalleregenStatus[globalItemId] ? (
                      <CircularProgress
                        size={20}
                        style={{
                          height: "20px",
                          width: "20px",
                          marginRight: "10px",
                          marginLeft: "16px",
                        }}
                        sx={{ color: "#8B5CFF" }}
                      />
                    ) : (
                      <>
                        {itemallergenStatus === 0 ? (
                          <img
                            src={HideEye}
                            alt="edit"
                            style={{
                              height: "20px",
                              width: "20px",
                              marginRight: "10px",
                              marginLeft: "16px",
                            }}
                            onClick={() => {
                              changeStatus(
                                "item_allergens",
                                globalItemId,
                                items_sub_list?.item_allergens[0]
                              );
                            }}
                          />
                        ) : (
                          <img
                            src={Eye}
                            alt="edit"
                            style={{
                              height: "20px",
                              width: "20px",
                              marginRight: "10px",
                              marginLeft: "16px",
                            }}
                            onClick={() => {
                              changeStatus(
                                "item_allergens",
                                globalItemId,
                                items_sub_list?.item_allergens[0].id
                              );
                            }}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </List>
          ) : (
            <List sx={{ mt: 0, p: 0 }}>
              <div className={`deails - row`}>
                <div
                  className="item-list-details"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: "8px",
                  }}
                >
                  <div className="hot-selling">
                    <h3 className="hot-selling-header">No Allergens Found</h3>
                  </div>
                </div>
              </div>
            </List>
          )}
        </>
      )}
    </div>
  );
};

export default AllergenComponent;
