import { List } from "@mui/material";
import ArrowDown from "../../../assets/images/ArrowDown.png";
import ArrowUp from "../../../assets/images/ArrowUp.png";
import React from "react";

type TaxComponentProps = {
  taxHide: any;
  TaxHandler: any;
  TaxHandlerClose: any;
  taxData: any;
};

const TaxComponent = ({
  taxHide,
  TaxHandler,
  TaxHandlerClose,
  taxData,
}: TaxComponentProps) => {
  return (
    <div>
      <div
        style={{
          width: "38.28vw",
          height: "7.35vh",
          alignItems: "center",
          marginTop: "16px",
          paddingLeft: "16px",
          paddingBottom: "16px",
          borderWidth: 1,
          borderColor: "#201C1C52",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div className="item-list-container">
          <div className="item-list-sub-container">
            <p className="list-title">Tax</p>
          </div>
          <div className="operate-container">
            {!taxHide ? (
              <button
                onClick={TaxHandler}
                className="show-btn"
                style={{ backgroundColor: "#FBFBFB" }}
              >
                <img
                  src={ArrowDown}
                  alt="down"
                  style={{ height: "20px", width: "20px", marginRight: "10px" }}
                />
                Show
              </button>
            ) : (
              <button
                onClick={TaxHandlerClose}
                className="show-btn"
                style={{ backgroundColor: "#FBFBFB" }}
              >
                <img
                  src={ArrowUp}
                  alt="up"
                  style={{ height: "20px", width: "20px", marginRight: "10px" }}
                />
                Hide
              </button>
            )}
          </div>
        </div>
      </div>
      {taxHide && (
        <List sx={{ mt: 0, p: 0 }}>
          <div
            style={{
              width: "38.28vw",
              alignItems: "center",
              paddingLeft: "16px",
              borderWidth: 1,
              borderColor: "#201C1C52",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                width: "37.18vw",
                alignItems: "center",
                borderWidth: 1,
                borderColor: " #201C1C52",
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: " #FBFBFB",
                padding: "16px",
              }}
            >
              <div
                className="item-list-sub-container"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div className="hot-selling">
                  <h3 className="hot-selling-header">{taxData.name}</h3>
                </div>
              </div>
              <div className="operate-container">
                <h3 className="price">{taxData.percentage} %</h3>
              </div>
            </div>
          </div>
        </List>
      )}
    </div>
  );
};

export default TaxComponent;
