import { Box } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import { log } from "console";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";

import image from "../../assets/images/profile.png";
import ConfirmationModal from "../../components/ConfirmationModal";
import { useAppDispatch } from "../../Hooks";
import { setIsLogin, setLocationAdded, setPosConnect, setPosConnectSelected, setStoreLimit } from "../../store/reducers/AuthReducer";
import { clearVendorInfo } from "../../store/reducers/VendorDetailsReducer";
import LinkAccount from "../LinkAccount/LinkAccount";

import "./SidebarStyles.css";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import _ from "lodash";

let PosAccountStatus: any;
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `none`,
  borderRadius: "8px",
  backgroundColor: "#F8F8F8F8",
  width: "100%",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const SidebarNav = () => {
  const dispatch = useAppDispatch();
  const Navigation = useNavigate();

  const { pathname } = useLocation();

  const { VendorList } = useSelector((state: any) => state.vendorDetails);

  const [isCurrentTab, setCurrentTab] = useState("");

  const [profileName, setProfileName]: any = useState("");
  const [profileEmail, setProfileEmail]: any = useState("");
  const [logoutModel, setLogoutModel]: any = useState(false);
  const [activeSection, setActiveSection] = useState("");
  const [profileImage, setProfileImage]: any = useState("");

  const [current, setCurrent] = useState("");
  const [PosAccountStatus, setPosAccountStatus] = useState(null);

  const tab = localStorage.getItem("tab");
  const [isOpen, setIsOpen] = useState({
    Business: tab == "" || tab == undefined || tab == "Business" ? true : false,
    Styling: tab == "Styling" ? true : false,
    Account: tab == "Account" ? true : false,
  });
  const { posConnection, user } = useSelector((state: any)=> state?.Auth)
  const navigate = useNavigate();

  console.log(user, "USER-sidebar")

  useEffect(() => {
    setProfileImage(VendorList.vendor_details?.profile_image);
  }, [VendorList]);

  useEffect(() => {
    // const PosAccount: any = localStorage.getItem("PosAccount");
    setPosAccountStatus(posConnection);
    console.log(posConnection,"SIDEBAR")
    // if (posConnection === 0 || null) {
    //   navigate("/linkaccount");
    // }
    const isAuthenticated = localStorage.getItem("IsLogin");
    if (!isAuthenticated) {
      dispatch(setIsLogin(true));
    } else {
      dispatch(setIsLogin(false));
    }
    if(_.isEmpty(user)){
      PosAccounStatus();
    }
  }, []);

  const PosAccounStatus = async () => {
    try {
      
      const Usercode = localStorage.getItem("UserCode");
      const response = await HelperModule().getMethod(
        ApiServices.base_URL + ApiServices.get_vendor + Usercode
      );
      console.log(response?.data?.vendor_details?.pos_account_status, "pos_account_status")
      if (response.status === true) {
        dispatch(setPosConnectSelected(false))
        dispatch(setPosConnect(response?.data?.vendor_details?.pos_account_status))
        dispatch(setLocationAdded(response?.data?.vendor_details?.pos_account?.location_added))
        dispatch(setStoreLimit(response?.data?.plan_details?.store_limit))
        setProfileName(response?.data?.vendor_details?.name);
        setProfileEmail(response?.data?.vendor_details?.email);
        console.log(response?.data,"LINK")
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (!activeSection) {
    let current = localStorage.getItem("activeSection");
    setCurrent(localStorage.getItem("current") || "");
    setActiveSection(current || "Insight");
  }

  useEffect(() => {
    const User = localStorage.getItem("UserName");
    const UserNameCreateProfile = localStorage.getItem("UserNameCreateProfile");
    setProfileName(user?.name);
    const Email = localStorage.getItem("Email");
    const emailCreateProfile = localStorage.getItem("emailCreateProfile");
    setProfileEmail(user?.email);
  }, []);

  const onLogout = () => {
    dispatch(clearVendorInfo());
    localStorage.clear();
    dispatch(setIsLogin(false));
  };

  const handleNavigation = (to: string, section: string, tab: string) => {
    // const PosAccount: any = localStorage.getItem("PosAccount");
    if (posConnection === 0 || null) {
      Navigation("/linkaccount");
    } else {
      Navigation(to);
      setActiveSection(section);
      localStorage.setItem("activeSection", section);
      localStorage.setItem("current", section);
      localStorage.setItem("tab", tab);
    }
  };

  useEffect(() => {
    //open menu accordion based on url path
    let activeMenu = "";
    let activeTitle = pathname.split("/")[1];
    activeTitle =
      activeTitle.substring(0, 1).toUpperCase() + activeTitle.substring(1);

    Account.map((item) => {
      if (item.to === pathname) {
        activeMenu = "Account";
      }
    });
    Styling.map((item) => {
      if (item.to === pathname) {
        activeMenu = "Styling";
      }
    });
    BusinessHub.map((item) => {
      if (item.to === pathname) {
        activeMenu = "Business";
      }
    });

    if (
      ["MenuDetails", "Location", "Customeruserdetails", "Promos", "Promotioncreation", "Promotionupdate", ""].includes(activeTitle)
    ) {
      activeMenu = "Business";
    }

    if (["BillingInfoTable", "Subscription"].includes(activeTitle)) {
      activeMenu = "Account";
    }

    let payload = {
      Business: false,
      Styling: false,
      Account: false,
    };

    setIsOpen({
      ...payload,
      [activeMenu]: true,
    });

    setCurrentTab(activeMenu);

    setActiveSection(
      activeTitle == "Dashboard"
        ? "Insight"
        : activeTitle == "MenuDetails"
          ? "Catalog"
          : activeTitle == "Location"
            ? "Manage"
            : activeTitle == "Customeruserdetails"
              ? "Customers"
              : activeTitle == "Promos"
                ? "Promos"
                : activeTitle == "Promotioncreation"
                  ? "Promos"
                  : activeTitle == "Promotionupdate"
                    ? "Promos"
                    : activeTitle == "BillingInfoTable"
                      ? "Settings"
                      : activeTitle == "Subscription"
                        ? "Settings"
                        : activeTitle
    );
  }, [pathname]);

  return (
    <Fragment>
      <Drawer className="sidebar-container" elevation={0} variant={"permanent"} anchor="left" sx={{ width: "280px", border: "none", backgroundColor: "transparent", display: "flex", "& .MuiDrawer-paper": { width: "280px", border: "none", boxShadow: "none", backgroundColor: "transparent", display: "flex", padding: 2, }, }}  >
        <Box sx={{ borderRadius: "8px", height: "100%", backgroundColor: "#FFFFFF", overflow: "hidden", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-start", overflowY: "auto", flexShrink: 0, "&::-webkit-scrollbar": { width: 10, }, "&::-webkit-scrollbar-track": { borderRadius: "50px", }, "&::-webkit-scrollbar-thumb": { border: "3px solid rgba(0, 0, 0, 0)", backgroundClip: "padding-box", borderRadius: 9999, backgroundColor: "#AAAAAA", }, "&::-webkit-scrollbar-thumb:hover": { border: "3px solid rgba(0, 0, 0, 0)", backgroundClip: "padding-box", borderRadius: 9999, backgroundColor: "gray", }, }}    >
          <Box height={'100%'} display={'flex'} flexDirection={'column'}>
            <Box className="subContainer" style={{ marginBottom: "10px" }}>
              <img src={require("../../assets/images/logo_main_img.png")} alt="Logo" className="logo" />
            </Box>

            <Box flexGrow={'1'} overflow={'auto'}>
              <div style={{ display: "flex", flexDirection: "column", gap: "10px", width: "100%", padding: "10px", overflowY: "scroll", }}     >
                <Accordion expanded={isOpen?.Business || isCurrentTab == "Business"} onChange={() => { setIsOpen({ ...isOpen, Business: isOpen?.Business ? false : true, Styling: false, Account: false, }); }} disableGutters={true} elevation={0}  >
                  <AccordionSummary
                    expandIcon={
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none"    >
                        <path d="M6.5415 9.5L12.5415 15.5L18.5415 9.5" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p className="accordion-title">Business Hub</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    {BusinessHub?.map((value, index) => (
                      <div key={index} onClick={() => { PosAccountStatus !== null && handleNavigation(value?.to, value?.title, "Business"); }} style={{ background: activeSection === value?.title ? "rgba(139, 92, 255, 0.40)" : "transparent", display: "flex", padding: "4px 12px", alignItems: "center", gap: "12px", borderRadius: "8px", width: "100%", cursor: PosAccountStatus !== null ? "pointer" : "not-allowed", }}    >
                        <img src={value?.icon} alt="Logo" className="icon" />
                        <span className="span" style={{ textDecoration: "none", color: "black", width: "130px", }}>{value?.title}</span>
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={isOpen?.Styling || isCurrentTab == "Styling"} onChange={() => { setIsOpen({ ...isOpen, Business: false, Styling: isOpen?.Styling ? false : true, Account: false, }); }} disableGutters={true} elevation={0}    >
                  <AccordionSummary
                    expandIcon={
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none"     >
                        <path d="M6.5415 9.5L12.5415 15.5L18.5415 9.5" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p className="accordion-title">Styling</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    {Styling?.map((value, index) => (
                      <div key={index} onClick={() => { PosAccountStatus !== null && handleNavigation(value?.to, value?.title, "Styling"); }} style={{ background: activeSection === value?.title ? "rgba(139, 92, 255, 0.40)" : "transparent", display: "flex", padding: "4px 12px", alignItems: "center", gap: "12px", borderRadius: "8px", width: "100%", cursor: PosAccountStatus !== null ? "pointer" : "not-allowed", }}        >
                        <img src={value?.icon} alt="Logo" className="icon" />
                        <span className="span" style={{ textDecoration: "none", color: "black", width: "130px", }}        >
                          {value?.title}
                        </span>
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={isOpen?.Account || isCurrentTab == "Account"} onChange={() => { setIsOpen({ ...isOpen, Business: false, Styling: false, Account: isOpen?.Account ? false : true, }); }} disableGutters={true} elevation={0} >
                  <AccordionSummary
                    expandIcon={
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none"    >
                        <path d="M6.5415 9.5L12.5415 15.5L18.5415 9.5" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p className="accordion-title">Account</p>
                  </AccordionSummary>
                  <AccordionDetails style={{ height: '150px', overflow: "auto" }}>
                    {Account?.map((value, index) => (
                      <div key={index} onClick={() => { PosAccountStatus !== null && handleNavigation(value?.to, value?.title, "Account"); }} style={{ background: activeSection === value?.title ? "rgba(139, 92, 255, 0.40)" : "transparent", display: "flex", padding: "4px 12px", alignItems: "center", gap: "12px", borderRadius: "8px", width: "100%", cursor: PosAccountStatus !== null ? "pointer" : "not-allowed", }}      >
                        <img src={value?.icon} alt="Logo" className="icon" />
                        <span className="span" style={{ textDecoration: "none", color: "black", width: "130px", }}        >
                          {value?.title}
                        </span>
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
              </div>
            </Box>

            <Box className="profileContainer" p={1}>
              <Box display={'flex'} alignItems={'center'} columnGap={1}>
                {profileImage ? (
                  <img src={profileImage} alt="Logo" className="profileIcon" />
                ) : (
                  <div className="profileIcon" style={{ backgroundColor: "#8B5CFF", display: "flex", justifyContent: "center", alignItems: "center", color: "white", fontWeight: "bold", textTransform: "uppercase" }}>
                    {profileName && profileName.charAt(0)}
                  </div>
                )}
                <div className="profileSubContainer">
                  <h1 className="profileName" style={{ width: "110px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", margin: 0 }}>{profileName}</h1>
                  <h1 className="profileSubTitle" style={{ width: "110px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", margin: 0 }}>{profileEmail}</h1>
                </div>
              </Box>
              <div onClick={() => setLogoutModel(true)} className="span" style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img src={require("../../assets/images/logout.png")} alt="Logo" className="icon" />
              </div>
            </Box>

          </Box>
          <div>
            {/* <div className="profileContainer" style={{}}>
							<img src={profileImage || image} alt="Logo" className="profileIcon" />
							<div className="profileSubContainer">
								<h1 className="profileName" style={{ width: "110px", textOverflow: "ellipsis", whiteSpace: "nowrap", }}>{profileName}</h1>
								<h1 className="profileSubTitle" style={{ width: "110px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", paddingTop: "5px", }}>{profileEmail}</h1>
							</div>
							<div onClick={() => setLogoutModel(true)} className="span" style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", paddingRight: "30px", }}>
								<img src={require("../../assets/images/logout.png")} alt="Logo" className="icon" />
							</div>
						</div> */}
          </div>
        </Box>
      </Drawer>

      <ConfirmationModal
        open={logoutModel}
        onConfirm={onLogout}
        onCancel={() => setLogoutModel(false)}
        headerText="Are you sure?"
        bodyText="You want to logout"
      />
    </Fragment>
  );
};

export default SidebarNav;

const BusinessHub = [
  {
    id: 1,
    title: "Insight",
    to: "/dashboard",
    icon: require("../../assets/images/Sales.png"),
  },
  {
    id: 2,
    title: "Catalog",
    to: "/catalog",
    icon: require("../../assets/images/Catalog.png"),
  },
  {
    id: 3,
    title: "Manage",
    to: "/manage",
    icon: require("../../assets/images/ManageIcon.png"),
  },
  {
    id: 4,
    title: "Customers",
    to: "/customers",
    icon: require("../../assets/images/users.png"),
  },
  {
    id: 5,
    title: "Promos",
    to: "/promos",
    icon: require("../../assets/images/PromoIcon.png"),
  },
];

const Styling = [
  {
    id: 1,
    title: "Display",
    to: "/display",
    icon: require("../../assets/images/Display.png"),
  },
  // {
  // 	id: 2,
  // 	title: "Behaviour",
  // 	to: "/behaviour",
  // 	icon: require("../../assets/images/behaviour.png"),
  // },
  {
    id: 2,
    title: "Theme",
    to: "/theme",
    icon: require("../../assets/images/Theme.png"),
  },
  // {
  //   id: 4,
  //   title: "Advanced",
  //   to: "/advanced",
  //   icon: require("../../assets/images/Advanced.png"),
  // },
];
const Account = [
  {
    id: 1,
    title: "Settings",
    to: "/settings",
    icon: require("../../assets/images/Manage.png"),
  },
  {
    id: 2,
    title: "Referrals",
    to: "/referrals",
    icon: require("../../assets/images/Referrals.png"),
  },
  {
    id: 3,
    title: "Support",
    to: "/support",
    icon: require("../../assets/images/orders.png"),
  },
];
