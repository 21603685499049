import { Box, CircularProgress, List } from "@mui/material";
import React from "react";
import ArrowDown from "../../../assets/images/ArrowDown.png";
import ArrowUp from "../../../assets/images/ArrowUp.png";
import Eye from "../../../assets/images/eye.png";
import HideEye from "../../../assets/images/hide-eye.png";
import Edit from "../../../assets/images/edit.png";
import FoodBowl from "../../../assets/images/foodBowl.png";

type UpsellingItemsComponentProps = {
  tagHide: any;
  UpSellingOpen: any;
  UpsellingClose: any;
  UpSellingModal: any;
  loadingUpsellingStatus: any;
  globalItemId: any;
  upSellingStatus: any;
  changeStatus: any;
  upsellingLoading: any;
  UpSellingList: any;
};

const UpsellingItemsComponent = ({
  tagHide,
  UpSellingOpen,
  UpsellingClose,
  UpSellingModal,
  loadingUpsellingStatus,
  globalItemId,
  upSellingStatus,
  changeStatus,
  upsellingLoading,
  UpSellingList,
}: UpsellingItemsComponentProps) => {
  return (
    <div>
      <div
        className={`deails - row`}
        style={{ marginLeft: "16px", marginTop: "6px" }}
      >
        <div className="item-list-container">
          <div className="item-list-sub-container">
            <p className="list-title">Up Selling</p>
          </div>
          <div className="operate-container">
            {!tagHide ? (
              <button
                onClick={() => UpSellingOpen()}
                className="show-btn"
                style={{ backgroundColor: "#FBFBFB" }}
              >
                <img
                  src={ArrowDown}
                  alt="up"
                  style={{ height: "20px", width: "20px", marginRight: "10px" }}
                />
                Show
              </button>
            ) : (
              <button
                onClick={UpsellingClose}
                className="show-btn"
                style={{ backgroundColor: "#FBFBFB" }}
              >
                <img
                  src={ArrowUp}
                  alt="down"
                  style={{ height: "20px", width: "20px", marginRight: "10px" }}
                />
                Hide
              </button>
            )}
            <button
              onClick={UpSellingModal}
              className="show-btn"
              style={{ backgroundColor: "#FBFBFB" }}
            >
              <img
                src={Edit}
                alt="edit"
                style={{ height: "20px", width: "20px", marginRight: "10px" }}
              />
              Edit
            </button>
            {loadingUpsellingStatus[globalItemId] ? (
              <CircularProgress
                size={20}
                style={{
                  height: "20px",
                  width: "20px",
                  marginRight: "10px",
                  marginLeft: "16px",
                }}
                sx={{ color: "#8B5CFF" }}
              />
            ) : (
              <>
                {upSellingStatus === 0 ? (
                  <img
                    src={HideEye}
                    alt="edit"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "10px",
                      marginLeft: "16px",
                    }}
                    onClick={() => {
                      changeStatus("upselling", globalItemId, null);
                    }}
                  />
                ) : (
                  <img
                    src={Eye}
                    alt="edit"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "10px",
                      marginLeft: "16px",
                    }}
                    onClick={() => {
                      changeStatus("upselling", globalItemId, null);
                    }}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {tagHide && (
        <>
          {upsellingLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
              }}
            >
              <CircularProgress
                size={20}
                style={{
                  height: "20px",
                  width: "20px",
                  marginRight: "10px",
                  marginLeft: "16px",
                }}
                sx={{ color: "#8B5CFF" }}
              />
            </Box>
          ) : (
            <>
              {UpSellingList && UpSellingList.length > 0 ? (
                UpSellingList.map((entry: any, index: any) => (
                  <List key={index} sx={{ mt: 0, p: 0 }}>
                    <div
                      className={`details-row`}
                      style={{ marginLeft: "16px" }}
                    >
                      <div
                        className="item-list-details"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingBottom: "16px",
                          }}
                        >
                          <div className="item-list-sub-container">
                            <div className="hot-selling">
                              <img
                                alt="bowl"
                                src={FoodBowl}
                                style={{
                                  height: "24px",
                                  width: "24px",
                                  marginLeft: "16px",
                                }}
                              />
                              <h3
                                style={{
                                  color:
                                    entry.upselling_status === 1
                                      ? "#000"
                                      : "#888",
                                }}
                                className="hot-selling-header"
                              >
                                {entry.name}
                              </h3>
                            </div>
                          </div>
                          <div className="operate-container"></div>
                        </div>
                      </div>
                    </div>
                  </List>
                ))
              ) : (
                <List sx={{ mt: 0, p: 0 }}>
                  <div className={`details-row`} style={{ marginLeft: "16px" }}>
                    <div
                      className="item-list-details"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingBottom: "8px",
                      }}
                    >
                      <div className="hot-selling">
                        <h3 className="hot-selling-header">
                          No Up-Selling Items
                        </h3>
                      </div>
                    </div>
                  </div>
                </List>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default UpsellingItemsComponent;
